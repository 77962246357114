import React from "react";
import AddressForm from "./AddressForm";

const Address = ({
  formData,
  handleTwoLevelInputChange,
  expandedSections,
  copyAddress,
  errors, // Pass errors as a prop
}) => {
  return (
    <div className={`p-4 ${expandedSections.address ? "block" : "hidden"}`}>
      {/* Permanent Address */}
      <AddressForm
        addressType="address_permanent"
        formData={formData}
        handleTwoLevelInputChange={handleTwoLevelInputChange}
        title="Permanent Address"
        showCopyButton={false}
        errors={errors} 
      />

      {/* Working Address */}
      <AddressForm
        addressType="address_working"
        formData={formData}
        handleTwoLevelInputChange={handleTwoLevelInputChange}
        title="Working Address"
        showCopyButton={true}
        onCopy={() => copyAddress("address_permanent", "address_working")}
        errors={errors} 
      />
    </div>
  );
};

export default Address;
