import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Home as HomeIcon,
  Users as UserGroupIcon,
  List as CategoryIcon,
  MessageCircle as MessageIcon,
  LogOut as LogOutIcon,
  X as CloseIcon,
  Menu as MenuIcon,
  CreditCard,
} from "lucide-react";
import { useDispatch } from "react-redux";
import { logout } from "../utils/authSlice";

const Sidebar = ({ isMobile, isOpen, onClose }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const menuItems = [
    { path: "/dashboard", icon: HomeIcon, label: "Dashboard" },
    {
      path: "/BeneficiaryForm",
      icon: UserGroupIcon,
      label: "Beneficiary Form",
    },
    {
      path: "/beneficiaries",
      icon: UserGroupIcon,
      label: "Beneficiary Management",
    },
    {path: "/payment", icon: CreditCard, label: "Payment"},
    { path: "/categorization", icon: CategoryIcon, label: "Categorization" },
    { path: "/sms", icon: MessageIcon, label: "SMS Service" },
  ];

  const sidebarClasses = `
    ${
      isMobile
        ? `fixed z-50 top-0 left-0 w-full h-full transform transition-transform duration-300 ease-in-out 
        ${isOpen ? "translate-x-0" : "-translate-x-full"}
        md:static md:translate-x-0 md:block`
        : "w-64"
    }
    bg-white shadow-md border-r flex flex-col h-full
  `;

  const handleLogout = () => {
    dispatch(logout());
    navigate("/", { replace: true });
  };

  return (
    <>
      {isMobile && isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40 md:hidden"
          onClick={onClose}
          aria-label="Close Sidebar Overlay"
        />
      )}
      <div
        className={sidebarClasses}
        aria-hidden={!isOpen && isMobile}
        role="complementary"
      >
        <div className="flex flex-col h-full">
          <div className="p-4 md:p-6 border-b flex justify-between items-center">
            <h1 className="text-xl md:text-2xl font-bold text-gray-800">
              MIMO System
            </h1>
            {isMobile && (
              <button
                onClick={onClose}
                className="md:hidden text-gray-600 focus:outline-none"
                aria-label="Close Sidebar"
              >
                <CloseIcon size={24} />
              </button>
            )}
          </div>

          <nav className="flex-grow">
            {menuItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                onClick={isMobile ? onClose : undefined}
                className={`
                  flex items-center p-3 md:p-4 transition-colors duration-200
                  ${
                    location.pathname === item.path
                      ? "bg-blue-50 text-blue-600 border-r-4 border-blue-600"
                      : "text-gray-600 hover:bg-gray-50"
                  }
                `}
                aria-current={
                  location.pathname === item.path ? "page" : undefined
                }
              >
                <item.icon className="mr-3" size={20} />
                {item.label}
              </Link>
            ))}
          </nav>

          <div className="p-4 border-t">
            <button
              onClick={handleLogout}
              className="flex items-center w-full p-3 transition-colors duration-200 text-gray-600 hover:bg-gray-50 hover:text-red-600"
              aria-label="Logout"
            >
              <LogOutIcon className="mr-3" size={20} />
              Logout
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

Sidebar.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const AppWithSidebar = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="relative">
      {/* Mobile Hamburger Menu */}
      <button
        onClick={toggleSidebar}
        className="fixed top-2 right-2 z-50 md:hidden bg-white shadow-md p-2 rounded-full"
        aria-label={isSidebarOpen ? "Close Sidebar" : "Open Sidebar"}
      >
        {isSidebarOpen ? <CloseIcon size={24} /> : <MenuIcon size={24} />}
      </button>

      {/* Sidebar */}
      <Sidebar
        isMobile={true}
        isOpen={isSidebarOpen}
        onClose={() => setSidebarOpen(false)}
      />
    </div>
  );
};

export default AppWithSidebar;
