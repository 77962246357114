import React from 'react';
import AddressField from './AddressField';

const FarmingInOtherMunicipalities = ({
  formData,
  handleInputChange,
  handleTwoLevelInputChange,
  handleNestedInputChange,
  expandedSections,
  errors
}) => {
  return (
    <div className={`p-4 ${expandedSections.farmingInOtherMunicipalities ? "block" : "hidden"}`}>
      <h3 className="font-semibold text-lg">Farming in Other Municipalities</h3>

      {/* Top-level status radio buttons */}
      <label className="block">
        <input
          type="radio"
          name="farmingInOtherMunicipalities.status"
          value="yes"
          checked={formData.farmingInOtherMunicipalities.status === "yes"}
          onChange={(e) =>
          handleInputChange(e)
          }
          className="mr-2"
        />
        Yes
      </label>
      <label className="block">
        <input
          type="radio"
          name="farmingInOtherMunicipalities.status"
          value="no"
          checked={formData.farmingInOtherMunicipalities.status === "no"}
          onChange={(e) =>
            handleNestedInputChange("farmingInOtherMunicipalities", "status", null, e.target.value)
          }
          className="mr-2"
        />
        No
      </label>
      {errors.farmingInOtherMunicipalitiesStatus && (
        <p className="text-red-500 text-sm mt-1">{errors.farmingInOtherMunicipalitiesStatus}</p>
      )}

      {formData.farmingInOtherMunicipalities.status === "yes" && (
        <div>
          {/* Location inputs */}
          <div className="mt-4">
            <h4 className="text-sm font-semibold">Location</h4>
            <div className="grid gap-4 grid-cols-2">
              <AddressField
                label="Province"
                name="location.province"
                value={formData.farmingInOtherMunicipalities.location.province || ""}
                onChange={(e) =>
                  handleNestedInputChange(
                    "farmingInOtherMunicipalities",
                    "location",
                    "province",
                    e.target.value
                  )
                }
                className={`${errors.locationProvince ? "border-red-500" : ""}`}
              />
              <AddressField
                label="District"
                name="location.district"
                value={formData.farmingInOtherMunicipalities.location.district || ""}
                onChange={(e) =>
                  handleNestedInputChange(
                    "farmingInOtherMunicipalities",
                    "location",
                    "district",
                    e.target.value
                  )
                }
                className={`${errors.locationDistrict ? "border-red-500" : ""}`}
              />
              <AddressField
                label="Municipality"
                name="location.municipality"
                value={formData.farmingInOtherMunicipalities.location.municipality || ""}
                onChange={(e) =>
                  handleNestedInputChange(
                    "farmingInOtherMunicipalities",
                    "location",
                    "municipality",
                    e.target.value
                  )
                }
                className={`${errors.locationMunicipality ? "border-red-500" : ""}`}
              />
              <AddressField
                label="Ward"
                name="location.ward"
                value={formData.farmingInOtherMunicipalities.location.ward || ""}
                onChange={(e) =>
                  handleNestedInputChange(
                    "farmingInOtherMunicipalities",
                    "location",
                    "ward",
                    e.target.value
                  )
                }
                className={`${errors.locationWard ? "border-red-500" : ""}`}
              />
            </div>
            {/* Location errors */}
            {errors.locationProvince && (
              <p className="text-red-500 text-sm mt-1">{errors.locationProvince}</p>
            )}
            {errors.locationDistrict && (
              <p className="text-red-500 text-sm mt-1">{errors.locationDistrict}</p>
            )}
            {errors.locationMunicipality && (
              <p className="text-red-500 text-sm mt-1">{errors.locationMunicipality}</p>
            )}
            {errors.locationWard && (
              <p className="text-red-500 text-sm mt-1">{errors.locationWard}</p>
            )}
          </div>

          {/* Area inputs */}
          <div className="mt-4">
            <h4 className="text-sm font-semibold">Area</h4>
            <div className="grid gap-4 grid-cols-2">
              <AddressField
                label="Bigha"
                name="area.bigha"
                value={formData.farmingInOtherMunicipalities.area.bigha || ""}
                onChange={(e) =>
                  handleNestedInputChange(
                    "farmingInOtherMunicipalities",
                    "area",
                    "bigha",
                    e.target.value
                  )
                }
                className={`${errors.areaBigha ? "border-red-500" : ""}`}
              />
              <AddressField
                label="Katha"
                name="area.katha"
                value={formData.farmingInOtherMunicipalities.area.katha || ""}
                onChange={(e) =>
                  handleNestedInputChange(
                    "farmingInOtherMunicipalities",
                    "area",
                    "katha",
                    e.target.value
                  )
                }
                className={`${errors.areaKatha ? "border-red-500" : ""}`}
              />
              <AddressField
                label="Dhur"
                name="area.dhur"
                value={formData.farmingInOtherMunicipalities.area.dhur || ""}
                onChange={(e) =>
                  handleNestedInputChange(
                    "farmingInOtherMunicipalities",
                    "area",
                    "dhur",
                    e.target.value
                  )
                }
                className={`${errors.areaDhur ? "border-red-500" : ""}`}
              />
            </div>
            {/* Area errors */}
            {errors.areaBigha && (
              <p className="text-red-500 text-sm mt-1">{errors.areaBigha}</p>
            )}
            {errors.areaKatha && (
              <p className="text-red-500 text-sm mt-1">{errors.areaKatha}</p>
            )}
            {errors.areaDhur && (
              <p className="text-red-500 text-sm mt-1">{errors.areaDhur}</p>
            )}
          </div>

          {/* Irrigation facility */}
          <div className="mt-4">
            <h4 className="text-sm font-semibold">Irrigation Facility</h4>
            <label className="block">
              <input
                type="radio"
                name="irrigationFacility"
                value="yes"
                checked={formData.farmingInOtherMunicipalities.irrigationFacility === "yes"}
                onChange={(e) =>
                  handleTwoLevelInputChange(
                    "farmingInOtherMunicipalities",
                    "irrigationFacility",
                    e.target.value
                  )
                }
                className="mr-2"
              />
              Yes
            </label>
            <label className="block">
              <input
                type="radio"
                name="irrigationFacility"
                value="no"
                checked={formData.farmingInOtherMunicipalities.irrigationFacility === "no"}
                onChange={(e) =>
                  handleTwoLevelInputChange(
                    "farmingInOtherMunicipalities",
                    "irrigationFacility",
                    e.target.value
                  )
                }
                className="mr-2"
              />
              No
            </label>
            {errors.irrigationFacility && (
              <p className="text-red-500 text-sm mt-1">{errors.irrigationFacility}</p>
            )}
          </div>

          {/* Field in Agriculture */}
          <div className="mt-4">
            <h4 className="text-sm font-semibold">Field in Agriculture</h4>
            <label className="block">
              <input
                type="radio"
                name="fieldInAgriculture"
                value="Krishi/Bali"
                checked={formData.farmingInOtherMunicipalities.fieldInAgriculture === "Krishi/Bali"}
                onChange={(e) =>
                  handleTwoLevelInputChange(
                    "farmingInOtherMunicipalities",
                    "fieldInAgriculture",
                    e.target.value
                  )
                }
                className="mr-2"
              />
              Krishi/Bali
            </label>
            <label className="block">
              <input
                type="radio"
                name="fieldInAgriculture"
                value="Pasupanchi"
                checked={formData.farmingInOtherMunicipalities.fieldInAgriculture === "Pasupanchi"}
                onChange={(e) =>
                  handleTwoLevelInputChange(
                    "farmingInOtherMunicipalities",
                    "fieldInAgriculture",
                    e.target.value
                  )
                }
                className="mr-2"
              />
              Pasupanchi
            </label>
            <label className="block">
              <input
                type="radio"
                name="fieldInAgriculture"
                value="Mix"
                checked={formData.farmingInOtherMunicipalities.fieldInAgriculture === "Mix"}
                onChange={(e) =>
                  handleTwoLevelInputChange(
                    "farmingInOtherMunicipalities",
                    "fieldInAgriculture",
                    e.target.value
                  )
                }
                className="mr-2"
              />
              Mix
            </label>
            {errors.fieldInAgriculture && (
              <p className="text-red-500 text-sm mt-1">{errors.fieldInAgriculture}</p>
            )}
          </div>

          {/* Specific Field in Agriculture */}
          <div className="mt-4">
            <label className="block text-sm font-medium">Specific Field in Agriculture</label>
            <input
              type="text"
              name="specificField"
              value={formData.farmingInOtherMunicipalities.specificField}
              onChange={(e) =>
                handleTwoLevelInputChange(
                  "farmingInOtherMunicipalities",
                  "specificField",
                  e.target.value
                )
              }
              className={`mt-1 block w-full border rounded-md p-2 ${errors.specificField ? "border-red-500" : ""}`}
              placeholder="Enter specific field in agriculture"
            />
            {errors.specificField && (
              <p className="text-red-500 text-sm mt-1">{errors.specificField}</p>
            )}
          </div>

          {/* Ownership */}
          <div className="mt-4">
            <h4 className="text-sm font-semibold">Ownership</h4>
            <label className="block">
              <input
                type="radio"
                name="ownership"
                value="self"
                checked={formData.farmingInOtherMunicipalities.ownership === "self"}
                onChange={(e) =>
                  handleTwoLevelInputChange(
                    "farmingInOtherMunicipalities",
                    "ownership",
                    e.target.value
                  )
                }
                className="mr-2"
              />
              Self
            </label>
            <label className="block">
              <input
                type="radio"
                name="ownership"
                value="lease"
                checked={formData.farmingInOtherMunicipalities.ownership === "lease"}
                onChange={(e) =>
                  handleTwoLevelInputChange(
                    "farmingInOtherMunicipalities",
                    "ownership",
                    e.target.value
                  )
                }
                className="mr-2"
              />
              Lease
            </label>
            {errors.ownership && (
              <p className="text-red-500 text-sm mt-1">{errors.ownership}</p>
            )}

            {formData.farmingInOtherMunicipalities.ownership === "lease" && (
              <div className="mt-4">
                <label className="block text-sm font-medium">Name of Owner</label>
                <input
                  type="text"
                  name="ownerName"
                  value={formData.farmingInOtherMunicipalities.ownerName}
                  onChange={(e) =>
                    handleTwoLevelInputChange(
                      "farmingInOtherMunicipalities",
                      "ownerName",
                      e.target.value
                    )
                  }
                  className={`mt-1 block w-full border rounded-md p-2 ${
                    errors.ownerName ? "border-red-500" : ""
                  }`}
                  placeholder="Enter name of the land owner"
                />
                {errors.ownerName && (
                  <p className="text-red-500 text-sm mt-1">{errors.ownerName}</p>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FarmingInOtherMunicipalities;