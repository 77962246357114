import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Dashboard from "./pages/Dashboard";
import BeneficiaryForm from "./pages/BeneficiaryForm";
import ProfileManagement from "./pages/ProfileManagement";
import CategorizationPage from "./pages/CategorizationPage";
import SMSPage from "./pages/SMSPage";
import LoginPage from "./pages/LoginPage";
import LandingPage from "./pages/LandingPage";
import SignupPage from "./pages/SignupPage";
import NotFoundPage from "./pages/NotFoundPage";
import Payment from "./pages/Payment";

import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();

  // Redirect logged-in users from landing page to dashboard
  useEffect(() => {
    if (isLoggedIn && location.pathname === "/") {
      navigate("/dashboard", { replace: true });
    }
  }, [isLoggedIn, location.pathname, navigate]);

  // Toggle sidebar state
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      {/* Header for mobile */}
      {isLoggedIn && <Header toggleSidebar={toggleSidebar} />}

      {/* Sidebar for mobile and desktop */}
      {isLoggedIn && (
        <Sidebar
          isMobile={true}
          isOpen={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
        />
      )}

      {/* Main Content Area */}
      <main
        className={`flex-1 overflow-y-auto ${isLoggedIn ? "p-0 md:p-6" : ""}`}
      >
        <Routes>
          {!isLoggedIn ? (
            // Public Routes
            <>
              <Route path="*" element={<NotFoundPage />} />
              <Route path="/" element={<LandingPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignupPage />} />
            </>
          ) : (
            // Protected Routes
            <>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/beneficiaryForm" element={<BeneficiaryForm />} />
              <Route path="/beneficiaries" element={<ProfileManagement />} />
              <Route path="/categorization" element={<CategorizationPage />} />
              <Route path="/sms" element={<SMSPage />} />
              <Route path="*" element={<Navigate to="/dashboard" replace />} />
              <Route path="/payment" element={<Payment />} />
            </>
          )}
        </Routes>
      </main>
    </div>
  );
}

export default App;
