import React from 'react';

const CropProduction = ({
  formData,
  handleInputChange,
  handleCheckboxChange,
  handleNestedCheckboxChange,
  handleNestedInputChange,
  handleNestedThreeInputChange,
  setFormData,
  expandedSections,
  errors // Add this line to accept errors prop
}) => {
  const mainCrops = ["Vegetables", "Fruits", "Seeds", "Mushroom", "Bees"];
  const cropVarieties = ["mango", "banana", "tomato", "papaya", "others"];
  const areaTypes = ["bigha", "katha", "dhur"];

  return (
    <div className={`p-4 ${expandedSections.cropProduction ? "block" : "hidden"}`}>
      {/* Main Crop Options */}
      <div className="mb-4">
        <label className="block text-sm font-medium">Types of Crops Grown</label>
        <div className="flex flex-wrap items-center space-x-4">
          {mainCrops.map((crop) => (
            <label key={crop} className="mr-4">
              <input
                type="checkbox"
                value={crop.toLowerCase()}
                checked={formData.cropsProduction?.includes(crop.toLowerCase())}
                onChange={(e) => handleCheckboxChange(e, "cropsProduction")}
                className="mr-2"
              />
              {crop}
            </label>
          ))}
        </div>
        {errors.cropsProduction && (
          <p className="text-red-500 text-sm mt-1">{errors.cropsProduction}</p>
        )}
      </div>

      {/* Sub-options for Vegetables, Fruits, Seeds */}
      {["vegetables", "fruits", "seeds"].map(
        (cropType) =>
          formData.cropsProduction?.includes(cropType) && (
            <div key={cropType} className="mb-6 border-t pt-4">
              <h3 className="text-sm font-medium">
                {cropType.charAt(0).toUpperCase() + cropType.slice(1)} Varieties
              </h3>
              <div className="mb-4">
                <label className="block text-sm font-medium">
                  Select {cropType.charAt(0).toUpperCase() + cropType.slice(1)}
                </label>
                <div className="flex flex-wrap items-center space-x-4">
                  {cropVarieties.map((item) => (
                    <label key={item}>
                      <input
                        type="checkbox"
                        value={item}
                        name={`cropDetails.${cropType}`}
                        checked={formData.cropDetails?.[cropType]?.includes(item)}
                        onChange={(e) => handleNestedCheckboxChange(e)}
                        className="mr-2"
                      />
                      {item.charAt(0).toUpperCase() + item.slice(1)}
                    </label>
                  ))}
                </div>
                {errors[`cropDetails.${cropType}`] && (
                  <p className="text-red-500 text-sm mt-1">{errors[`cropDetails.${cropType}`]}</p>
                )}

                {/* "Others" Input - Always visible when others is checked */}
                {formData.cropDetails?.[cropType]?.includes("others") && (
                  <input
                    type="text"
                    name={`cropDetails.${cropType}OtherVariety`}
                    value={formData.cropDetails?.[`${cropType}OtherVariety`] || ""}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        cropDetails: {
                          ...prev.cropDetails,
                          [`${cropType}OtherVariety`]: e.target.value,
                        },
                      }))
                    }
                    placeholder={`Specify other ${cropType}`}
                    className="mt-2 block w-full border rounded-md p-2"
                  />
                )}
              </div>

              {formData.cropDetails?.[cropType]?.map((item) => (
                <div key={item} className="mb-4">
                  <h4 className="text-sm font-medium capitalize">{item} Details</h4>
                  <div className="grid grid-cols-2 gap-4 mt-2">
                    <div>
                      <label className="block text-sm font-medium">Number of Trees</label>
                      <input
                        type="number"
                        name={`cropDetailsSpecific.${cropType}.${item}.number`}
                        value={formData.cropDetailsSpecific?.[cropType]?.[item]?.number || ""}
                        placeholder={`Enter number of ${item}`}
                        onChange={(e) =>
                          handleNestedThreeInputChange(
                            "cropDetailsSpecific",
                            cropType,
                            item,
                            "number",
                            e.target.value
                          )
                        }
                        className={`mt-1 block w-full border rounded-md p-2 ${errors[`cropDetailsSpecific.${cropType}.${item}.number`] ? "border-red-500" : ""}`}
                      />
                      {errors[`cropDetailsSpecific.${cropType}.${item}.number`] && (
                        <p className="text-red-500 text-sm mt-1">{errors[`cropDetailsSpecific.${cropType}.${item}.number`]}</p>
                      )}
                    </div>
                    <div>
                      <label className="block text-sm font-medium">Production (kg/unit)</label>
                      <input
                        type="number"
                        name={`cropDetailsSpecific.${cropType}.${item}.production`}
                        value={formData.cropDetailsSpecific?.[cropType]?.[item]?.production || ""}
                        onChange={(e) =>
                          handleNestedThreeInputChange(
                            "cropDetailsSpecific",
                            cropType,
                            item,
                            "production",
                            e.target.value
                          )
                        }
                        className={`mt-1 block w-full border rounded-md p-2 ${errors[`cropDetailsSpecific.${cropType}.${item}.production`] ? "border-red-500" : ""}`}
                        placeholder={`Enter production for ${item} (kg/unit)`}
                      />
                      {errors[`cropDetailsSpecific.${cropType}.${item}.production`] && (
                        <p className="text-red-500 text-sm mt-1">{errors[`cropDetailsSpecific.${cropType}.${item}.production`]}</p>
                      )}
                    </div>

                    {/* Area Inputs (Bigha, Katha, Dhur) */}
                    {areaTypes.map((areaType) => (
                      <div key={areaType}>
                        <label className="block text-sm font-medium">
                          Area ({areaType.charAt(0).toUpperCase() + areaType.slice(1)})
                        </label>
                        <input
                          type="number"
                          name={`cropDetailsSpecific.${cropType}.${item}.area_${areaType}`}
                          value={formData.cropDetailsSpecific?.[cropType]?.[item]?.[`area_${areaType}`] || ""}
                          onChange={(e) =>
                            handleNestedThreeInputChange(
                              "cropDetailsSpecific",
                              cropType,
                              item,
                              `area_${areaType}`,
                              e.target.value
                            )
                          }
                          className={`mt-1 block w-full border rounded-md p-2 ${errors[`cropDetailsSpecific.${cropType}.${item}.area_${areaType}`] ? "border-red-500" : ""}`}
                          placeholder={`Enter area for ${item} (${areaType.charAt(0).toUpperCase() + areaType.slice(1)})`}
                        />
                        {errors[`cropDetailsSpecific.${cropType}.${item}.area_${areaType}`] && (
                          <p className="text-red-500 text-sm mt-1">{errors[`cropDetailsSpecific.${cropType}.${item}.area_${areaType}`]}</p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )
      )}

      {/* Sub-options for Mushroom and Bees */}
      {["mushroom", "bees"].map(
        (cropType) =>
          formData.cropsProduction?.includes(cropType) && (
            <div key={cropType} className="mb-6 border-t pt-4">
              <h3 className="text-sm font-medium">
                {cropType.charAt(0).toUpperCase() + cropType.slice(1)} Details
              </h3>
              <div className="grid grid-cols-2 gap-4">
                <input
                  type="number"
                  name={`cropDetailsSpecific.${cropType}.number`}
                  value={formData.cropDetailsSpecific?.[cropType]?.number || ""}
                  placeholder={`Enter number of ${cropType}`}
                  onChange={(e) =>
                    handleNestedInputChange("cropDetailsSpecific", cropType, "number", e.target.value)
                  }
                  className={`mt-1 block w-full border rounded-md p-2 ${errors[`cropDetailsSpecific.${cropType}.number`] ? "border-red-500" : ""}`}
                />
                {errors[`cropDetailsSpecific.${cropType}.number`] && (
                  <p className="text-red-500 text-sm mt-1">{errors[`cropDetailsSpecific.${cropType}.number`]}</p>
                )}

                <input
                  type="number"
                  name={`cropDetailsSpecific.${cropType}.production`}
                  value={formData.cropDetailsSpecific?.[cropType]?.production || ""}
                  placeholder={`Enter production (kg)`}
                  onChange={(e) =>
                    handleNestedInputChange("cropDetailsSpecific", cropType, "production", e.target.value)
                  }
                  className={`mt-1 block w-full border rounded-md p-2 ${errors[`cropDetailsSpecific.${cropType}.production`] ? "border-red-500" : ""}`}
                />
                {errors[`cropDetailsSpecific.${cropType}.production`] && (
                  <p className="text-red-500 text-sm mt-1">{errors[`cropDetailsSpecific.${cropType}.production`]}</p>
                )}
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default CropProduction;