import React from "react";

const BusinessDetails = ({
  formData,
  handleTwoLevelInputChange,
  expandedSections,
  errors // 
}) => {
  return (
    <div className={`p-4 ${expandedSections.familyDetails ? "block" : "hidden"}`}>
      <h2 className="text-lg font-medium mb-4">
        Business/Firm/Enterprise Details
      </h2>
      <div className="mb-4">
        <label className="block text-sm font-medium">
          Business/Firm/Enterprise Registrant No.
        </label>
        <input
          type="text"
          name="registrant_no"
          value={formData.business?.registrant_no || ""}
          onChange={(e) =>
            handleTwoLevelInputChange(
              "business",
              "registrant_no",
              e.target.value
            )
          }
          className={`mt-1 block w-full border rounded-md p-2 ${errors.registrant_no ? "border-red-500" : ""}`}
          placeholder="Enter Registrant Number"
        />
        {errors.registrant_no && (
          <p className="text-red-500 text-sm mt-1">{errors.registrant_no}</p>
        )}
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">Registration Date</label>
        <input
          type="date"
          name="registration_date"
          value={formData.business?.registration_date || ""}
          onChange={(e) =>
            handleTwoLevelInputChange(
              "business",
              "registration_date",
              e.target.value
            )
          }
          className={`mt-1 block w-full border rounded-md p-2 ${errors.registration_date ? "border-red-500" : ""}`}
        />
        {errors.registration_date && (
          <p className="text-red-500 text-sm mt-1">{errors.registration_date}</p>
        )}
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">
          Name of Business/Firm/Enterprise
        </label>
        <input
          type="text"
          name="business_name"
          value={formData.business?.business_name || ""}
          onChange={(e) =>
            handleTwoLevelInputChange(
              "business",
              "business_name",
              e.target.value
            )
          }
          className={`mt-1 block w-full border rounded-md p-2 ${errors.business_name ? "border-red-500" : ""}`}
          placeholder="Enter Name of Business/Firm/Enterprise"
        />
        {errors.business_name && (
          <p className="text-red-500 text-sm mt-1">{errors.business_name}</p>
        )}
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">Registered Office</label>
        <input
          type="text"
          name="registered_office"
          value={formData.business?.registered_office || ""}
          onChange={(e) =>
            handleTwoLevelInputChange(
              "business",
              "registered_office",
              e.target.value
            )
          }
          className={`mt-1 block w-full border rounded-md p-2 ${errors.registered_office ? "border-red-500" : ""}`}
          placeholder="Enter Registered Office"
        />
        {errors.registered_office && (
          <p className="text-red-500 text-sm mt-1">{errors.registered_office}</p>
        )}
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">PAN No.</label>
        <input
          type="number"
          name="pan_no"
          value={formData.business?.pan_no || ""}
          onChange={(e) =>
            handleTwoLevelInputChange("business", "pan_no", e.target.value)
          }
          className={`mt-1 block w-full border rounded-md p-2 ${errors.pan_no ? "border-red-500" : ""}`}
          placeholder="Enter PAN No."
        />
        {errors.pan_no && (
          <p className="text-red-500 text-sm mt-1">{errors.pan_no}</p>
        )}
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">
          Name of Chairman/Director
        </label>
        <input
          type="text"
          name="chairman_director"
          value={formData.business?.chairman_director || ""}
          onChange={(e) =>
            handleTwoLevelInputChange(
              "business",
              "chairman_director",
              e.target.value
            )
          }
          className={`mt-1 block w-full border rounded-md p-2 ${errors.chairman_director ? "border-red-500" : ""}`}
          placeholder="Enter Name of Chairman/Director"
        />
        {errors.chairman_director && (
          <p className="text-red-500 text-sm mt-1">{errors.chairman_director}</p>
        )}
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">
          Type of Occupation/Firm/Enterprise
        </label>
        <select
          name="occupation_type"
          value={formData.business?.occupation_type || ""}
          onChange={(e) =>
            handleTwoLevelInputChange(
              "business",
              "occupation_type",
              e.target.value
            )
          }
          className={`mt-1 block w-full border rounded-md p-2 ${errors.occupation_type ? "border-red-500" : ""}`}
        >
          <option value="">Select Type</option>
          <option value="agriculture">Agriculture</option>
          <option value="livestock">Livestock</option>
          <option value="mixed">Mixed</option>
          {/* Add more options as needed */}
        </select>
        {errors.occupation_type && (
          <p className="text-red-500 text-sm mt-1">{errors.occupation_type}</p>
        )}
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">Number of Members</label>
        <input
          type="number"
          name="number_of_members"
          value={formData.business?.number_of_members || ""}
          onChange={(e) =>
            handleTwoLevelInputChange(
              "business",
              "number_of_members",
              e.target.value
            )
          }
          className={`mt-1 block w-full border rounded-md p-2 ${errors.number_of_members ? "border-red-500" : ""}`}
          placeholder="Enter Number of Members"
        />
        {errors.number_of_members && (
          <p className="text-red-500 text-sm mt-1">{errors.number_of_members}</p>
        )}
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">
          Number of Annual Employment Creation
        </label>
        <input
          type="number"
          name="employment_creation"
          value={formData.business?.employment_creation || ""}
          onChange={(e) =>
            handleTwoLevelInputChange(
              "business",
              "employment_creation",
              e.target.value
            )
          }
          className={`mt-1 block w-full border rounded-md p-2 ${errors.employment_creation ? "border-red-500" : ""}`}
          placeholder="Enter Number of Employment Creation"
        />
        {errors.employment_creation && (
          <p className="text-red-500 text-sm mt-1">{errors.employment_creation}</p>
        )}
      </div>
    </div>
  );
};

export default BusinessDetails;