import React, { useState} from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import {
  ArrowLeft,
  CreditCard,
  Calendar,
  FileText,
  AlertTriangle,
  Clock,
} from "lucide-react";

const PaymentManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const phoneNumber = searchParams.get("phone");
  const farmerData = location.state?.farmerData;

  const [paymentHistory, setPaymentHistory] = useState([
    {
      id: 1,
      date: "2024-03-15",
      amount: 1000,
      description: "Initial payment",
      status: "completed",
    },
    {
      id: 2,
      date: "2024-03-20",
      amount: 1500,
      description: "Second installment",
      status: "completed",
    },
  ]);

  const [newPayment, setNewPayment] = useState({
    amount: "",
    description: "",
    date: new Date().toISOString().split("T")[0],
  });

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPayment((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError(""); // Clear error when user starts typing
  };

  const validatePayment = () => {
    if (
      !newPayment.amount ||
      isNaN(newPayment.amount) ||
      newPayment.amount <= 0
    ) {
      setError("Please enter a valid amount");
      return false;
    }
    if (!newPayment.description.trim()) {
      setError("Please enter a payment description");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validatePayment()) return;
    setShowConfirmDialog(true);
  };

  const confirmPayment = async () => {
    try {
      const paymentData = {
        ...newPayment,
        status: "completed",
        id: Date.now(),
        phoneNumber,
      };

      setPaymentHistory((prev) => [...prev, paymentData]);
      setNewPayment({
        amount: "",
        description: "",
        date: new Date().toISOString().split("T")[0],
      });
      setShowConfirmDialog(false);
    } catch (error) {
      setError("Failed to process payment. Please try again.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Top Navigation Bar */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 mb-6">
          <button
            onClick={() => navigate(-1)}
            className="inline-flex items-center text-gray-600 hover:text-gray-900 transition-colors"
          >
            <ArrowLeft className="mr-2" size={20} />
            <span className="font-medium">Back </span>
          </button>
        </div>

        {!phoneNumber || !farmerData?.name ? (
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-8 text-center">
            <div className="max-w-md mx-auto p-4">
              <h2 className="text-2xl font-semibold text-gray-900 mb-3">
                Please follow these steps:
              </h2>
              <ul className="text-left text-gray-700">
                <li>1. Go to Profile Management.</li>
                <li>2. Select the name of the farmer.</li>
                <li>3. Click on "Add Payment".</li>
              </ul>
              <div className="mt-6 mb-6">
                <h2 className="text-2xl font-semibold text-gray-900 mb-3">
                  कृपया यी चरणहरू पालना गर्नुहोस्:
                </h2>
                <ul className="text-left text-gray-700">
                  <li>१. प्रोफाइल व्यवस्थापनमा जानुहोस्।</li>
                  <li>२. किसानको नाम चयन गर्नुहोस्।</li>
                  <li>३. "भुक्तानी थप्नुहोस्" मा क्लिक गर्नुहोस्।</li>
                </ul>
              </div>

              <button
                onClick={() => navigate("/beneficiaries")}
                className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg transition-colors inline-flex items-center text-lg"
              >
                Go to Profile Management
                <ArrowLeft className="ml-2" size={20} />
              </button>
            </div>
          </div>
        ) : (
          <>
            {/* Farmer Info Card */}
            <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 mb-6">
              <h1 className="text-2xl font-bold text-gray-900 mb-2">
                Payment Management
              </h1>
              <div className="flex flex-col sm:flex-row sm:items-center gap-4">
                <div className="flex items-center">
                  <span className="text-gray-500 mr-2">Farmer:</span>
                  <span className="font-semibold text-gray-900">
                    {farmerData?.name}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="text-gray-500 mr-2">Phone:</span>
                  <span className="font-semibold text-gray-900">
                    {phoneNumber}
                  </span>
                </div>
              </div>
            </div>

            {/* New Payment Card */}
            <div className="bg-white rounded-lg shadow-sm border border-gray-200 mb-6">
              <div className="p-6 border-b border-gray-200">
                <h2 className="text-xl font-semibold flex items-center text-gray-900">
                  <CreditCard className="mr-3 text-blue-600" />
                  Add New Payment
                </h2>
              </div>
              <div className="p-6">
                <div className="space-y-6">
                  {error && (
                    <div className="bg-red-50 text-red-600 p-4 rounded-lg flex items-center">
                      <AlertTriangle className="mr-3 flex-shrink-0" size={20} />
                      <p>{error}</p>
                    </div>
                  )}

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Amount
                      </label>
                      <div className="relative">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 font-medium">
                          रू
                        </span>
                        <input
                          type="number"
                          name="amount"
                          value={newPayment.amount}
                          onChange={handleInputChange}
                          className="pl-10 w-full border border-gray-300 rounded-lg p-3 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                          placeholder="Enter amount"
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Payment Upto
                      </label>
                      <div className="relative">
                        <Calendar
                          className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                          size={18}
                        />
                        <input
                          type="date"
                          name="date"
                          value={newPayment.date}
                          onChange={handleInputChange}
                          className="pl-10 w-full border border-gray-300 rounded-lg p-3 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Description
                    </label>
                    <div className="relative">
                      <FileText
                        className="absolute left-3 top-3 text-gray-400"
                        size={18}
                      />
                      <textarea
                        name="description"
                        value={newPayment.description}
                        onChange={handleInputChange}
                        className="pl-10 w-full border border-gray-300 rounded-lg p-3 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                        rows="3"
                        placeholder="Enter payment description"
                      />
                    </div>
                  </div>

                  <div className="flex justify-end">
                    <button
                      onClick={handleSubmit}
                      className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg transition-colors text-sm font-medium inline-flex items-center"
                    >
                      Add Payment
                      <span className="ml-2">रू</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Payment History */}
            <div className="bg-white rounded-lg shadow-sm border border-gray-200">
              <div className="p-6 border-b border-gray-200">
                <h2 className="text-xl font-semibold flex items-center text-gray-900">
                  <Clock className="mr-3 text-blue-600" />
                  Payment History
                </h2>
              </div>
              <div className="p-6 overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="text-left py-3 px-4 text-sm font-medium text-gray-600">
                        Date
                      </th>
                      <th className="text-left py-3 px-4 text-sm font-medium text-gray-600">
                        Description
                      </th>
                      <th className="text-right py-3 px-4 text-sm font-medium text-gray-600">
                        Amount
                      </th>
                      <th className="text-left py-3 px-4 text-sm font-medium text-gray-600">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {paymentHistory.map((payment) => (
                      <tr
                        key={payment.id}
                        className="hover:bg-gray-50 transition-colors"
                      >
                        <td className="py-4 px-4 text-sm text-gray-900">
                          {payment.date}
                        </td>
                        <td className="py-4 px-4 text-sm text-gray-900">
                          {payment.description}
                        </td>
                        <td className="py-4 px-4 text-sm text-gray-900 text-right font-medium">
                          रू {payment.amount.toLocaleString()}
                        </td>
                        <td className="py-4 px-4">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            {payment.status}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        {/* Confirmation Dialog */}
        {showConfirmDialog && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg max-w-md w-full p-6 m-4">
              <h3 className="text-lg font-semibold mb-4">Confirm Payment</h3>
              <div className="bg-gray-50 p-4 rounded-lg mb-6 space-y-2">
                <p className="flex justify-between">
                  <span className="text-gray-600">Amount:</span>
                  <span className="font-medium">रू {newPayment.amount}</span>
                </p>
                <p className="flex justify-between">
                  <span className="text-gray-600">Date:</span>
                  <span className="font-medium">{newPayment.date}</span>
                </p>
                <p>
                  <span className="text-gray-600">Description:</span>
                  <span className="block mt-1 font-medium">
                    {newPayment.description}
                  </span>
                </p>
              </div>
              <div className="flex flex-col sm:flex-row gap-3 sm:justify-end">
                <button
                  onClick={() => setShowConfirmDialog(false)}
                  className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors w-full sm:w-auto"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmPayment}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors w-full sm:w-auto"
                >
                  Confirm Payment
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentManagement;
