// Validations.js

export const validatePage = (page, formData) => {
  let errors = {};

  switch (page) {
    case 1:
      errors = validateBasicInfo(formData);
      break;
    case 2:
      errors = {
        ...validateEducation(formData),
        ...validateAddress(formData),
        ...validateWorkDetails(formData),
        ...validateLandHoldings(formData),
        ...validateIncomeAndResources(formData),
      };
      break;
    case 3:
      errors = {
        ...validateFamilyDetails(formData),
        ...validateLivestockDetails(formData),
        ...validateCropProduction(formData),
        ...validateAgriculturalEquipment(formData),
      };
      break;
    case 4:
      errors = {
        ...validateFinancialInfo(formData),
        ...validateLandAccessibility(formData),
        ...validateFarmingInOtherMunicipalities(formData),
      };
      break;
    case 5:
      // errors = validateBusinessDetails(formData);
      break;
    default:
      break;
  }

  return errors;
};

const validateBasicInfo = (formData) => {
  const errors = {};

  if (!formData.englishFirstName) {
    errors.englishFirstName = "First Name is required";
  } else if (!/^[a-zA-Z]+$/.test(formData.englishFirstName)) {
    errors.englishFirstName = "Name should only contain English letters";
  }

  if (!formData.englishLastName) {
    errors.englishLastName = "Last Name is required";
  } else if (!/^[a-zA-Z]+$/.test(formData.englishLastName)) {
    errors.englishLastName = "Name should only contain English letters";
  }

  if (!formData.nepaliFirstName) {
    errors.nepaliFirstName = "First Name is required";
  } else if (!/^[\u0900-\u097F]+$/.test(formData.nepaliFirstName)) {
    errors.nepaliFirstName = "Name should only contain Nepali letters";
  }

  if (!formData.nepaliLastName) {
    errors.nepaliLastName = "Last Name is required";
  } else if (!/^[\u0900-\u097F]+$/.test(formData.nepaliLastName)) {
    errors.nepaliLastName = "Name should only contain Nepali letters";
  }

  if (!formData.phoneNumber || !/^\d{10}$/.test(formData.phoneNumber)) {
    errors.phoneNumber = "Valid Phone Number is required";
  }

  if (!formData.citizenshipNumber) {
    errors.citizenshipNumber = "Citizenship Number is required";
  }

  if (!formData.citizenshipIssueDate) {
    errors.citizenshipIssueDate = "Issue Date is required";
  }

  if (!formData.citizenshipDistrict) {
    errors.citizenshipDistrict = "District is required";
  }

  if (!formData.id_image) {
    errors.id_image = "ID Image is required";
  }

  return errors;
};

const validateEducation = (formData) => {
  const errors = {};

  // Validate Literacy Status
  if (!formData.literacyStatus) {
    errors.literacyStatus = "Please select a literacy status.";
  }

  // Validate Literacy Level (only if Literate)
  if (formData.literacyStatus === "Literate" && !formData.literacyLevel) {
    errors.literacyLevel = "Please select a literacy level.";
  }

  return errors;
};

const validateAddress = (formData) => {
  const errors = {};

  const requiredFields = [
    "province",
    "block",
    "district",
    "municipality",
    "ward",
    "tole",
  ];

  ["address_permanent", "address_working"].forEach((addressType) => {
    requiredFields.forEach((field) => {
      if (!formData[addressType][field]) {
        errors[`${addressType}.${field}`] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required`;
      }
    });
  });

  return errors;
};
const validateWorkDetails = (formData) => {
  const errors = {};

  // Validate agricultural sector selection
  if (!formData.agriculturalSector) {
    errors.agriculturalSector = "Please select an agricultural sector.";
  }

  // Validate years in the field if the agricultural sector is selected
  if (
    formData.agriculturalSector &&
    (formData.yearsInField === undefined || formData.yearsInField === "")
  ) {
    errors.yearsInField = "Please specify the number of years in the field.";
  } else if (formData.yearsInField < 0) {
    errors.yearsInField = "Years must be 0 or more.";
  }

  return errors;
};
const validateLandHoldings = (formData) => {
  const errors = {};

  // Validate land area inputs
  const landAreas = ["bigha", "katha", "dhur"];
  landAreas.forEach((areaType) => {
    if (
      formData.landArea?.[areaType]?.number === undefined ||
      formData.landArea[areaType].number === ""
    ) {
      errors[`landArea.${areaType}`] = `Land size (${areaType}) is required.`;
    } else if (formData.landArea[areaType].number < 0) {
      errors[
        `landArea.${areaType}`
      ] = `Land size (${areaType}) must be 0 or more.`;
    }
  });

  // Validate land type
  if (!formData.landType || formData.landType.length === 0) {
    errors.landType = "At least one land type must be selected.";
  }

  return errors;
};
const validateIncomeAndResources = (formData) => {
  const errors = {};

  // Validate Annual Income from Agriculture
  if (
    formData.annualIncomeFromAgriculture === undefined ||
    formData.annualIncomeFromAgriculture === ""
  ) {
    errors.annualIncomeFromAgriculture =
      "Annual income from agriculture is required.";
  } else if (formData.annualIncomeFromAgriculture < 0) {
    errors.annualIncomeFromAgriculture = "Annual income must be 0 or more.";
  }

  // Validate Annual Income from Other Sector
  if (
    formData.annualIncomeFromOther === undefined ||
    formData.annualIncomeFromOther === ""
  ) {
    errors.annualIncomeFromOther =
      "Annual income from other sector is required.";
  } else if (formData.annualIncomeFromOther < 0) {
    errors.annualIncomeFromOther = "Annual income must be 0 or more.";
  }

  // Validate Time Spent in Agriculture
  if (!formData.timeSpentInAgriculture) {
    errors.timeSpentInAgriculture = "Time spent in agriculture is required.";
  }

  return errors;
};

const validateFamilyDetails = (formData) => {
  const errors = {};

  // Validate Total Family Members
  if (formData.totalFamilyMembers === undefined || formData.totalFamilyMembers < 0) {
      errors.totalFamilyMembers = "Total Family Members must be 0 or more.";
  }

  if (formData.familyMembersInAgriculture === undefined || formData.familyMembersInAgriculture < 0) {
      errors.familyMembersInAgriculture = "Family Members in Agriculture must be 0 or more.";
  } else if (formData.familyMembersInAgriculture > formData.totalFamilyMembers) {
      errors.familyMembersInAgriculture = "Family Members in Agriculture cannot exceed Total Family Members.";
  }

  const genderCount = Object.values(formData.totalGendersInFamily).reduce((sum, value) => sum + (value || 0), 0);
  if (genderCount > formData.totalFamilyMembers) {
      errors.totalGendersInFamily = "Total gender count cannot exceed Total Family Members.";
  }

  formData.familyMemberDetails?.forEach((member, index) => {
      if (!member.fullName) {
          errors[`familyMemberDetails[${index}].fullName`] = "Full Name is required.";
      }
      if (!member.relation) {
          errors[`familyMemberDetails[${index}].relation`] = "Relation with Farmer is required.";
      }
      if (member.age === undefined || member.age < 0) {
          errors[`familyMemberDetails[${index}].age`] = "Age must be 0 or more.";
      }
      if (!member.gender) {
          errors[`familyMemberDetails[${index}].gender`] = "Gender is required.";
      }
      if (!member.citizenshipNo) {
          errors[`familyMemberDetails[${index}].citizenshipNo`] = "Citizenship No is required.";
      }
      if (!member.phoneNumber || !/^\d{10}$/.test(member.phoneNumber)) {
          errors[`familyMemberDetails[${index}].phoneNumber`] = "Valid Phone Number is required.";
      }
  });
  return errors;
};
const validateLivestockDetails = (formData) => {
  const errors = {};

  // Validate Types of Livestock Owned
  // if (!formData.livestockOwned || formData.livestockOwned.length === 0) {
  //     errors.livestockOwned = "At least one type of livestock must be selected.";
  // }

  // Validate Livestock Production Details
  formData.livestockOwned?.forEach((livestock) => {
      const livestockError = {};
      const livestockData = formData.livestockProduction[livestock] || {};

      if (livestock === "cow" || livestock === "buffalo") {
          if (!livestockData.number || livestockData.number < 0) {
              livestockError.number = "Number of livestock must be 0 or more.";
          }
          if (!livestockData.milkMonths || livestockData.milkMonths < 0) {
              livestockError.milkMonths = "Months of Milk Production must be 0 or more.";
          }
          if (!livestockData.milkPerDay || livestockData.milkPerDay < 0) {
              livestockError.milkPerDay = "Milk per day must be 0 or more.";
          }
      } else if (livestock === "OxBull" || livestock === "poultry" || livestock === "fish") {
          if (!livestockData.number || livestockData.number < 0) {
              livestockError.number = "Number of livestock must be 0 or more.";
          }
      } else if (livestock === "goat" || livestock === "Pig") {
          ["mau", "khasi", "boka", "patha/pathi"].forEach((category) => {
              if (!livestockData[category] || livestockData[category] < 0) {
                  livestockError[category] = `Number of ${category} must be 0 or more.`;
              }
          });
      }

      if (Object.keys(livestockError).length > 0) {
          errors[`livestockProduction.${livestock}`] = livestockError;
      }
  });

  return errors;
};
const validateCropProduction = (formData) => {
  const errors = {};

  // Validate Types of Crops Grown
  // if (!formData.cropsProduction || formData.cropsProduction.length === 0) {
  //     errors.cropsProduction = "At least one type of crop must be selected.";
  // }

  // Validate Crop Details
  formData.cropsProduction?.forEach((cropType) => {
      const cropError = {};
      const cropData = formData.cropDetails?.[cropType] || [];

      if (["vegetables", "fruits", "seeds"].includes(cropType)) {
          if (cropData.length === 0) {
              cropError[cropType] = `Select at least one variety for ${cropType}.`;
          }

          cropData.forEach((item) => {
              const itemError = {};
              const itemData = formData.cropDetailsSpecific?.[cropType]?.[item] || {};

              if (!itemData.number || itemData.number < 0) {
                  itemError.number = `Number of ${item} must be 0 or more.`;
              }
              if (!itemData.production || itemData.production < 0) {
                  itemError.production = `Production of ${item} must be 0 or more.`;
              }
              ["bigha", "katha", "dhur"].forEach((areaType) => {
                  if (!itemData[`area_${areaType}`] || itemData[`area_${areaType}`] < 0) {
                      itemError[`area_${areaType}`] = `Area (${areaType}) for ${item} must be 0 or more.`;
                  }
              });

              if (Object.keys(itemError).length > 0) {
                  cropError[item] = itemError;
              }
          });

      } else if (["mushroom", "bees"].includes(cropType)) {
          const cropSpecificData = formData.cropDetailsSpecific?.[cropType] || {};

          if (!cropSpecificData.number || cropSpecificData.number < 0) {
              cropError.number = `Number of ${cropType} must be 0 or more.`;
          }
          if (!cropSpecificData.production || cropSpecificData.production < 0) {
              cropError.production = `Production of ${cropType} must be 0 or more.`;
          }
      }

      if (Object.keys(cropError).length > 0) {
          errors[`cropDetailsSpecific.${cropType}`] = cropError;
      }
  });

  return errors;
};
const validateAgriculturalEquipment = (formData) => {
  const errors = {};

  // Validate Machinery Options
  if (!formData.machinery || formData.machinery.length === 0) {
      errors.machinery = "At least one machinery option must be selected.";
  }

  return errors;
};
const validateFinancialInfo = (formData) => {
  const errors = {};

  // Validate Agricultural Loans
  if (formData.agriculturalLoans.taken === undefined) {
      errors.agriculturalLoansTaken = "Please specify if agricultural loans have been taken.";
  }

  if (formData.agriculturalLoans.taken === "yes") {
      if (!formData.agriculturalLoans.source) {
          errors.agriculturalLoansSource = "Source of agricultural loans is required.";
      }
      if (formData.agriculturalLoans.amount === undefined || formData.agriculturalLoans.amount <= 0) {
          errors.agriculturalLoansAmount = "Amount of agricultural loans must be more than 0.";
      }
  }

  // Validate Agricultural Insurance
  if (formData.agriculturalInsurance.enrolled === undefined) {
      errors.agriculturalInsuranceEnrolled = "Please specify if enrolled in agricultural insurance.";
  }

  if (formData.agriculturalInsurance.enrolled === "yes") {
      if (!formData.agriculturalInsurance.insuredItems) {
          errors.agriculturalInsuranceInsuredItems = "Details of insured items are required.";
      }
  }

  return errors;
};
const validateLandAccessibility = (formData) => {
  const errors = {};

  // Validate Land Ownership
  if (!formData.landOwnership) {
      errors.landOwnership = "Land Ownership is required.";
  }

  // Validate Owner's Name if land is leased
  if (formData.landOwnership === "lease" && !formData.landOwnerName) {
      errors.landOwnerName = "Owner's Name is required for leased land.";
  }

  // Validate Lending Land
  if (formData.landLent === undefined) {
      errors.landLent = "Please specify if you have lent land to others.";
  }

  // Validate Lender's Details if land is lent
  if (formData.landLent === "yes") {
      if (!formData.lenderName) {
          errors.lenderName = "Lender's Name is required.";
      }
      if (!formData.landType) {
          errors.landType = "Land Type is required.";
      }
      // Validate Area in Biga, Katha, Dhur
      if (formData.areaBiga === undefined || formData.areaBiga < 0) {
          errors.areaBiga = "Area in Biga must be 0 or more.";
      }
      if (formData.areaKatha === undefined || formData.areaKatha < 0) {
          errors.areaKatha = "Area in Katha must be 0 or more.";
      }
      if (formData.areaDhur === undefined || formData.areaDhur < 0) {
          errors.areaDhur = "Area in Dhur must be 0 or more.";
      }
      // Validate Irrigation Facility
      if (formData.irrigationFacility === undefined) {
          errors.irrigationFacility = "Please specify if there is an irrigation facility.";
      }
      // Validate Irrigated Land Area if irrigation facility is available
      if (formData.irrigationFacility === "yes") {
          if (formData.irrigatedLandAreaBiga === undefined || formData.irrigatedLandAreaBiga < 0) {
              errors.irrigatedLandAreaBiga = "Irrigated Land Area in Biga must be 0 or more.";
          }
          if (formData.irrigatedLandAreaKatha === undefined || formData.irrigatedLandAreaKatha < 0) {
              errors.irrigatedLandAreaKatha = "Irrigated Land Area in Katha must be 0 or more.";
          }
          if (formData.irrigatedLandAreaDhur === undefined || formData.irrigatedLandAreaDhur < 0) {
              errors.irrigatedLandAreaDhur = "Irrigated Land Area in Dhur must be 0 or more.";
          }
          if (!formData.irrigationSource) {
              errors.irrigationSource = "Irrigation Source is required.";
          }
      }
  }

  return errors;
};
const validateFarmingInOtherMunicipalities = (formData) => {
  const errors = {};

  // Validate Farming in Other Municipalities Status
  if (formData.farmingInOtherMunicipalities.status === undefined) {
      errors.farmingInOtherMunicipalitiesStatus = "Please specify if farming in other municipalities.";
  }

  if (formData.farmingInOtherMunicipalities.status === "yes") {
      // Validate Location
      if (!formData.farmingInOtherMunicipalities.location.province) {
          errors.locationProvince = "Province is required.";
      }
      if (!formData.farmingInOtherMunicipalities.location.district) {
          errors.locationDistrict = "District is required.";
      }
      if (!formData.farmingInOtherMunicipalities.location.municipality) {
          errors.locationMunicipality = "Municipality is required.";
      }
      if (!formData.farmingInOtherMunicipalities.location.ward) {
          errors.locationWard = "Ward is required.";
      }

      // Validate Area
      if (formData.farmingInOtherMunicipalities.area.bigha === undefined || formData.farmingInOtherMunicipalities.area.bigha < 0) {
          errors.areaBigha = "Area in Bigha must be 0 or more.";
      }
      if (formData.farmingInOtherMunicipalities.area.katha === undefined || formData.farmingInOtherMunicipalities.area.katha < 0) {
          errors.areaKatha = "Area in Katha must be 0 or more.";
      }
      if (formData.farmingInOtherMunicipalities.area.dhur === undefined || formData.farmingInOtherMunicipalities.area.dhur < 0) {
          errors.areaDhur = "Area in Dhur must be 0 or more.";
      }

      // Validate Irrigation Facility
      if (formData.farmingInOtherMunicipalities.irrigationFacility === undefined) {
          errors.irrigationFacility = "Please specify if there is an irrigation facility.";
      }

      // Validate Field in Agriculture
      if (!formData.farmingInOtherMunicipalities.fieldInAgriculture) {
          errors.fieldInAgriculture = "Field in agriculture is required.";
      }

      // Validate Specific Field in Agriculture
      if (!formData.farmingInOtherMunicipalities.specificField) {
          errors.specificField = "Specific field in agriculture is required.";
      }

      // Validate Ownership
      if (!formData.farmingInOtherMunicipalities.ownership) {
          errors.ownership = "Ownership is required.";
      }

      if (formData.farmingInOtherMunicipalities.ownership === "lease" && !formData.farmingInOtherMunicipalities.ownerName) {
          errors.ownerName = "Name of the owner is required for leased land.";
      }
  }

  return errors;
};
// const validateBusinessDetails = (formData) => {
//   const errors = {};
// //all optional
//   // Validate Registrant No
//   if (!formData.business?.registrant_no) {
//       errors.registrant_no = "Registrant No. is required.";
//   }

//   // Validate Registration Date
//   if (!formData.business?.registration_date) {
//       errors.registration_date = "Registration Date is required.";
//   }

//   // Validate Business Name
//   if (!formData.business?.business_name) {
//       errors.business_name = "Business Name is required.";
//   }

//   // Validate Registered Office
//   if (!formData.business?.registered_office) {
//       errors.registered_office = "Registered Office is required.";
//   }

//   // Validate PAN No
//   if (!formData.business?.pan_no) {
//       errors.pan_no = "PAN No. is required.";
//   }

//   // Validate Chairman/Director Name
//   if (!formData.business?.chairman_director) {
//       errors.chairman_director = "Chairman/Director Name is required.";
//   }

//   // Validate Occupation Type
//   if (!formData.business?.occupation_type) {
//       errors.occupation_type = "Occupation Type is required.";
//   }

//   // Validate Number of Members
//   if (formData.business?.number_of_members === undefined || formData.business?.number_of_members < 0) {
//       errors.number_of_members = "Number of Members must be 0 or more.";
//   }

//   // Validate Employment Creation
//   if (formData.business?.employment_creation === undefined || formData.business?.employment_creation < 0) {
//       errors.employment_creation = "Number of Annual Employment Creation must be 0 or more.";
//   }

//   return errors;
// };
export const validateFileUpload = (file) => {
  const errors = {};

  if (file) {
    const validTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!validTypes.includes(file.type)) {
      errors.id_image = "Please upload a valid image file (JPEG, PNG, or JPG)";
    }

    const maxSize = 2 * 1024 * 1024;
    if (file.size > maxSize) {
      errors.id_image = "File size should be less than 2MB";
    }
  }

  return errors;
};
