import React from 'react';

const AgriculturalEquipment = ({
  formData,
  handleCheckboxChange,
  expandedSections,
  errors // Add this line to accept errors prop
}) => {
  const machineryOptions = ["owned", "rented"];

  return (
    <div className={`p-4 ${expandedSections.agriculturalEquipment ? "block" : "hidden"}`}>
      <div className="mb-6">
        <h3 className="text-sm font-medium mb-2">Machinery:</h3>
        <div className="flex flex-col space-y-3">
          {machineryOptions.map((option) => (
            <label key={option} className="flex items-center">
              <input
                type="checkbox"
                value={option}
                checked={formData.machinery.includes(option)}
                onChange={(e) => handleCheckboxChange(e, "machinery")}
                className="mr-2"
              />
              {option.charAt(0).toUpperCase() + option.slice(1)} Machinery
            </label>
          ))}
        </div>
        {errors.machinery && (
          <p className="text-red-500 text-sm mt-1">{errors.machinery}</p>
        )}
      </div>
    </div>
  );
};

export default AgriculturalEquipment;