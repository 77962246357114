import React, { useState, useEffect } from "react";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";


const BasicInfo = ({
  formData,
  handleInputChange,
  handleNepaliDateChange,
  handleFileChange,
  errors,
}) => {
  // Local state to store object URL for preview
const [previewUrl, setPreviewUrl] = useState(null);
//nepalidate
// const [selectedDate, setSelectedDate] = useState("");
// Whenever formData.id_image changes, generate a new preview URL
useEffect(() => {
if (formData.id_image && typeof formData.id_image !== "string") {
const fileUrl = URL.createObjectURL(formData.id_image);
setPreviewUrl(fileUrl);

// Cleanup the object URL on unmount or when changing files
return () => {
URL.revokeObjectURL(fileUrl);
};
} else {
setPreviewUrl(null);
}
}, [formData.id_image]);
  return (
    <div className="container mx-auto p-6 bg-gray-50">
      <div className="bg-white shadow-md rounded-lg">
        <div className="p-4">
          {/* Name (English) */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">Name (English)</h3>
            <div className="grid md:grid-cols-3 gap-4">
              {["englishFirstName", "englishMiddleName", "englishLastName"].map(
                (field, index) => (
                  <div key={field}>
                    <label className="block text-sm font-medium">
                      {["First Name", "Middle Name", "Last Name"][index]}
                    </label>
                    <input
                      type="text"
                      name={field}
                      value={formData[field]}
                      onChange={handleInputChange}
                      className={`mt-1 block w-full border rounded-md p-2 ${
                        errors[field] ? "border-red-500" : ""
                      }`}
                    />
                    {errors[field] && (
                      <p className="text-red-500 text-sm mt-1">{errors[field]}</p>
                    )}
                  </div>
                )
              )}
            </div>
          </div>

          {/* Name (Nepali) */}
          <div className="mb-6">
          
            <h3 className="text-lg font-semibold mb-2">Name (Nepali)</h3>
            <div className="grid md:grid-cols-3 gap-4">
              {["nepaliFirstName", "nepaliMiddleName", "nepaliLastName"].map(
                (field, index) => (
                  <div key={field}>
                    <label className="block text-sm font-medium">
                      {["First Name", "Middle Name", "Last Name"][index]}
                    </label>
                    <input
                      type="text"
                      name={field}
                      value={formData[field]}
                      onChange={handleInputChange}
                      className={`mt-1 block w-full border rounded-md p-2 ${
                        errors[field] ? "border-red-500" : ""
                      }`}
                    />
                    {errors[field] && (
                      <p className="text-red-500 text-sm mt-1">{errors[field]}</p>
                    )}
                  </div>
                )
              )}
            </div>
          </div>

          {/* Contact */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2 block">Contact</h3>
            <div className="grid md:grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium">
                  Phone Number
                </label>
                <input
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  pattern="[0-9]{10}"
                  title="Please enter a valid 10-digit phone number"
                  className={`mt-1 block w-full border rounded-md p-2 ${
                    errors.phoneNumber ? "border-red-500" : ""
                  }`}
                />
                {errors.phoneNumber && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.phoneNumber}
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* Date of Birth and Gender */}
          <div className="mb-6 grid md:grid-cols-2 gap-4">
            <div>
              <label className="text-lg font-semibold mb-2">Date of Birth</label>
              <NepaliDatePicker
                value={formData.dateOfBirth}
                onChange={handleNepaliDateChange}
                className={`mt-1 block border rounded-md p-2 w-2/3 ${
                  errors.dateOfBirth ? "border-red-500" : ""
                }`}
              />
              {errors.dateOfBirth && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.dateOfBirth}
                </p>
              )}
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-2">Gender</h3>
              <div className="flex gap-4">
                {["male", "female", "Other"].map((option) => (
                  <label key={option} className="inline-flex items-center">
                    <input
                      type="radio"
                      name="gender"
                      value={option}
                      checked={formData.gender === option}
                      onChange={handleInputChange}
                      className={`form-radio h-4 w-4 text-blue-600 ${
                        errors.gender ? "border-red-500" : ""
                      }`}
                    />
                    <span className="ml-2">
                      {option.charAt(0).toUpperCase() + option.slice(1)}
                    </span>
                  </label>
                ))}
              </div>
              {errors.gender && (
                <p className="text-red-500 text-sm mt-1">{errors.gender}</p>
              )}
            </div>
          </div>

          {/* Citizenship Details */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">Citizenship Details</h3>
            <div className="grid md:grid-cols-3 gap-4">
              {[
                { field: "citizenshipNumber", label: "Citizenship Number" },
                { field: "citizenshipIssueDate", label: "Issuance Date" },
                { field: "citizenshipDistrict", label: "Issued By" },
              ].map(({ field, label }) => (
                <div key={field}>
                  <label className="block text-sm font-medium">{label}</label>
                  <input
                    type={field === "citizenshipIssueDate" ? "date" : "text"}
                    name={field}
                    value={formData[field]}
                    onChange={handleInputChange}
                    className={`mt-1 block w-full border rounded-md p-2 ${
                      errors[field] ? "border-red-500" : ""
                    }`}
                  />
                  {errors[field] && (
                    <p className="text-red-500 text-sm mt-1">{errors[field]}</p>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* ID Image Upload */}
          <div className="mb-6">
<label htmlFor="id_image" className="block">
Upload your ID Image:
</label>
<input
type="file"
name="id_image"
accept="image/jpeg, image/png, image/jpg, image/gif"
onChange={handleFileChange}
className={`mt-1 block w-full border rounded-md p-2 ${
errors.id_image ? "border-red-500" : ""
}`}
/>
{errors.id_image && (
<p className="text-red-500 text-sm mt-1">{errors.id_image}</p>
)}

{/* Preview */}
{previewUrl && (
<div className="mt-4">
<p className="text-sm mb-2">
Selected file: {formData.id_image?.name}
</p>
<img
src={previewUrl}
alt="preview"
className="border rounded-md max-w-sm"
/>
</div>
)}
</div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
