import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import "nepali-datepicker-reactjs/dist/index.css";
import axios from "axios";
import BasicInfo from "../components/Form/BasicInfo";
import Education from "../components/Form/Education";
import Address from "../components/Form/Address";
import WorkDetails from "../components/Form/WorkDetails";
import LandHoldings from "../components/Form/LandHoldings";
import IncomeAndResources from "../components/Form/IncomeAndResources";
import FamilyDetails from "../components/Form/FamilyDetails";
import LivestockDetails from "../components/Form/LivestockDetails";
import CropProduction from "../components/Form/CropProduction";
import AgriculturalEquipment from "../components/Form/AgriculturalEquipment";
import FinancialInfo from "../components/Form/FinancialInfo";
import LandAccessibility from "../components/Form/LandAccessibility";
import FarmingInOtherMunicipalities from "../components/Form/FarmingInOtherMunicipalities";
import BusinessDetails from "../components/Form/BusinessDetails";
import {
  validatePage,
  validateFileUpload,
} from "../components/Form/Validations";

const BeneficiaryForm = () => {
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (newPage) => {
    // Validate current page before moving
    const pageErrors = validatePage(currentPage, formData);

    if (Object.keys(pageErrors).length === 0) {
      setCurrentPage(newPage);
      setErrors({});
    } else {
      setErrors(pageErrors);
    }
  };
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    // Basic Information
    englishFirstName: "",
    englishMiddleName: "",
    englishLastName: "",
    nepaliFirstName: "",
    nepaliMiddleName: "",
    nepaliLastName: "",
    phoneNumber: "",
    dateOfBirth: "",
    gender: "",

    // Citizenship Details
    citizenshipNumber: "",
    citizenshipIssueDate: "",
    citizenshipDistrict: "",

    id_image: null,

    // Education
    literacyStatus: "",
    literacyLevel: "",

    //address

    address_permanent: {
      province: "",
      block: "",
      district: "",
      municipality: "",
      ward: "",
      tole: "",
    },
    address_working: {
      province: "",
      block: "",
      district: "",
      municipality: "",
      ward: "",
      tole: "",
    },

    //work Details
    agriculturalSector: "",
    yearsInField: 0,

    //landholdings
    landArea: {},
    landType: [],
    crops: [],
    cropArea: {},

    //income
    annualIncomeFromAgriculture: "",
    annualIncomeFromOther: "",
    timeSpentInAgriculture: "",

    //family details
    totalFamilyMembers: 1,
    familyMembersInAgriculture: 1,
    totalGendersInFamily: {
      male: 0,
      female: 0,
      other: 0,
    },
    familyMemberDetails: [],

    //livestock
    livestockOwned: [],
    livestockProduction: {
      goat: {},
      buffalo: {},
      poultry: {},
      fish: {},
      cow: {},
      OxBull: {},
      Pig: {},
    },

    //crop production
    cropsProduction: [],
    cropDetailsSpecific: {
      vegetables: [],
      fruits: [],
      seeds: [],
      mushroom: {},
      bees: {},
    },

    //agricultural equipment
    machinery: [],

    //financial info
    agriculturalLoans: {
      taken: "no",
      source: "",
      amount: "",
    },
    agriculturalInsurance: {
      enrolled: "no",
      insuredItems: "",
    },

    //land accessiblity
    //to deinfe

    //farming in other municipality
    farmingInOtherMunicipalities: {
      status: '',
      location: {
        province: '',
        district: '',
        municipality: '',
        ward: '',
      },
      area: {
        bigha: '',
        katha: '',
        dhur: '',
      },
      irrigationFacility: '',
      fieldInAgriculture: '',
      specificField: '',
      ownership: '',
      ownerName: '',
    },

    //business
    business: {
      registrant_no: "",
      registration_date: "",
      business_name: "",
      registered_office: "",
      pan_no: "",
      chairman_director: "",
      occupation_type: "",
      number_of_members: "",
      employment_creation: "",
    },
  });

  const [expandedSections, setExpandedSections] = useState({
    basicInfo: true,
    education: true,
    address: true,
    workDetails: true,
    landHoldings: true,
    familyDetails: true,
    income: true,
    livestock: true,
    cropProduction: true,
    agriculturalEquipment: true,
    financialInfo: true,
    landAccessibility: true,
    farmingInOtherMunicipalities: true,
    BusinessDetails: true,
  });

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "received");
    setFormData((prev) => {
      const keys = name.split(".");
      const updatedFormData = { ...prev };

      let current = updatedFormData;

      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          current[key] = value;
        } else {
          current[key] = current[key] || {};
          current = current[key];
        }
      });

      return updatedFormData;
    });
  };

  // Handle Nepali date picker change
  const handleNepaliDateChange = (value) => {
    // Directly set the value from the Nepali date picker
    setFormData({ ...formData, dateOfBirth: value });
  };

  //4 level huda lai
  const handleNestedThreeInputChange = (
    parentKey,
    childKey,
    nestedChildKey,
    nestedKey,
    value
  ) => {
    console.log(
      parentKey,
      childKey,
      nestedChildKey,
      nestedKey,
      value,
      "received"
    );
    setFormData((prev) => ({
      ...prev,
      [parentKey]: {
        ...prev[parentKey],
        [childKey]: {
          ...(prev[parentKey]?.[childKey] || {}),
          [nestedChildKey]: {
            ...(prev[parentKey]?.[childKey]?.[nestedChildKey] || {}),
            [nestedKey]: value,
          },
        },
      },
    }));
  };

  //3 ta level huda lai
  const handleNestedInputChange = (parentKey, childKey, nestedKey, value) => {
    console.log(parentKey, childKey, nestedKey, value, "received");
    setFormData((prev) => ({
      ...prev,
      [parentKey]: {
        ...prev[parentKey],
        [childKey]: {
          ...(prev[parentKey]?.[childKey] || {}),
          [nestedKey]: value,
        },
      },
    }));
  };
  const handleTwoLevelInputChange = (parentKey, childKey, value) => {
    console.log(parentKey, childKey, value, "received");
    setFormData((prev) => ({
      ...prev,
      [parentKey]: {
        ...prev[parentKey],
        [childKey]: value,
      },
    }));
  };

  const handleCheckboxChange = (e, section) => {
    const { value, checked } = e.target;
    console.log(value, checked, "received");

    setFormData((prevState) => {
      const updatedSection = prevState[section] ? [...prevState[section]] : [];

      if (checked) {
        if (!updatedSection.includes(value)) {
          updatedSection.push(value);
        }
      } else {
        const index = updatedSection.indexOf(value);
        if (index > -1) {
          updatedSection.splice(index, 1);
        }
      }
      return {
        ...prevState,
        [section]: updatedSection,
      };
    });
  };

  //nested checkboxes ko problem solve garna:
  const handleNestedCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    const [parentKey, childKey] = name.split(".");
    console.log(parentKey, childKey, value, checked, "received");
    setFormData((prev) => {
      const updatedFormData = { ...prev };

      if (!updatedFormData[parentKey]) {
        updatedFormData[parentKey] = {};
      }

      if (!updatedFormData[parentKey][childKey]) {
        updatedFormData[parentKey][childKey] = [];
      }

      if (checked) {
        if (!updatedFormData[parentKey][childKey].includes(value)) {
          updatedFormData[parentKey][childKey].push(value);
        }
      } else {
        updatedFormData[parentKey][childKey] = updatedFormData[parentKey][
          childKey
        ].filter((item) => item !== value);

        // Optionally remove the other variety input when unchecked
        if (value === "others") {
          delete updatedFormData[parentKey][`${childKey}OtherVariety`];
        }
      }

      return updatedFormData;
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileErrors = validateFileUpload(file);
      if (Object.keys(fileErrors).length > 0) {
        setErrors(fileErrors);
        return;
      }
      setFormData({
        ...formData,
        id_image: file,
      });
      setErrors({});
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a new FormData object
    const form = new FormData();

    for (const key in formData) {
      if (formData[key] instanceof Object && !(formData[key] instanceof File)) {
        // If it's a nested object (like 'address'), loop through its subkeys
        for (const subKey in formData[key]) {
          form.append(`${key}[${subKey}]`, formData[key][subKey]);
        }
      } else {
        form.append(key, formData[key]);
      }
    }

    try {
      const response = await axios.post(
        "http://127.0.0.1:8000/api/beneficiaries",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure headers match FormData requirements
          },
        }
      );

      console.log("Success:", response.data.message);
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.log("Validation Errors:", error.response.data.errors);
      } else if (error.request) {
        // No response was received from the server
        console.error("No response received:", error.request);
      } else {
        // Something happened while setting up the request
        console.error("Error:", error.message);
      }
    }

    // Log the formData to see the structure before sending (for debugging purposes)
    console.log("Form Data:", JSON.stringify(formData, null, 2));
  };

  const SectionHeader = ({ title, section }) => (
    <div
      className="flex justify-between items-center bg-blue-100 p-3 cursor-pointer"
      onClick={() => toggleSection(section)}
    >
      <h2 className="text-lg font-semibold">{title}</h2>
      {expandedSections[section] ? <ChevronUp /> : <ChevronDown />}
    </div>
  );

  const copyAddress = (fromKey, toKey) => {
    setFormData((prev) => ({
      ...prev,
      [toKey]: { ...prev[fromKey] },
    }));
  };

  return (
    <div className="container mx-auto p-6 bg-gray-50">
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg">
        {currentPage === 1 && (
          <>
            <SectionHeader title="Basic Information" section="basicInfo" />
            <BasicInfo
              formData={formData}
              handleInputChange={handleInputChange}
              handleNepaliDateChange={handleNepaliDateChange}
              handleFileChange={handleFileChange}
              errors={errors}
            />
          </>
        )}
        {currentPage === 2 && (
          <>
            <SectionHeader title="Education" section="education" />
            <Education
              formData={formData}
              handleInputChange={handleInputChange}
              expandedSections={expandedSections}
              errors={errors}
            />
            <SectionHeader title="Address" section="address" />
            <Address
              formData={formData}
              handleTwoLevelInputChange={handleTwoLevelInputChange}
              expandedSections={expandedSections}
              copyAddress={copyAddress}
              errors={errors}
            />
            <SectionHeader title="Work Details" section="workDetails" />
            <WorkDetails
              formData={formData}
              handleInputChange={handleInputChange}
              expandedSections={expandedSections}
              errors={errors}
            />
            <SectionHeader title="Land Holdings" section="landHoldings" />
            <LandHoldings
              formData={formData}
              handleNestedInputChange={handleNestedInputChange}
              handleCheckboxChange={handleCheckboxChange}
              expandedSections={expandedSections}
              errors={errors}
            />
            <SectionHeader title="Income and Resources" section="income" />
            <IncomeAndResources
              formData={formData}
              handleInputChange={handleInputChange}
              expandedSections={expandedSections}
              errors={errors}
            />
          </>
        )}
        {currentPage === 3 && (
          <>
            <SectionHeader title="Family Details" section="familyDetails" />
            <FamilyDetails
              formData={formData}
              setFormData={setFormData}
              handleInputChange={handleInputChange}
              handleNestedInputChange={handleNestedInputChange}
              handleNestedCheckboxChange={handleNestedCheckboxChange}
              handleCheckboxChange={handleCheckboxChange}
              expandedSections={expandedSections}
              errors={errors}
            />

            <SectionHeader title="Livestock Details" section="livestock" />
            <LivestockDetails
              formData={formData}
              handleInputChange={handleInputChange}
              handleCheckboxChange={handleCheckboxChange}
              handleNestedInputChange={handleNestedInputChange}
              expandedSections={expandedSections}
              errors={errors}
            />
            <SectionHeader title="Crop Production" section="cropProduction" />
            <CropProduction
              formData={formData}
              handleInputChange={handleInputChange}
              handleCheckboxChange={handleCheckboxChange}
              handleNestedCheckboxChange={handleNestedCheckboxChange}
              handleNestedInputChange={handleNestedInputChange}
              handleNestedThreeInputChange={handleNestedThreeInputChange}
              setFormData={setFormData}
              expandedSections={expandedSections}
              errors={errors}
            />
            <SectionHeader
              title="Agricultural Equipment"
              section="agriculturalEquipment"
            />
            <AgriculturalEquipment
              formData={formData}
              handleCheckboxChange={handleCheckboxChange}
              expandedSections={expandedSections}
              errors={errors}
            />
          </>
        )}

        {currentPage === 4 && (
          <>
            <SectionHeader
              title="Financial Information"
              section="financialInfo"
            />
            <FinancialInfo
              formData={formData}
              setFormData={setFormData}
              expandedSections={expandedSections}
              errors={errors}
            />
            <SectionHeader
              title="Land Accessibility"
              section="landAccessibility"
            />
            <LandAccessibility
              formData={formData}
              handleInputChange={handleInputChange}
              expandedSections={expandedSections}
              errors={errors}
            />
            <SectionHeader
              title="Farming in Other Municipalities"
              section="farmingInOtherMunicipalities"
            />
            <FarmingInOtherMunicipalities
              formData={formData}
              handleInputChange={handleInputChange}
              handleTwoLevelInputChange={handleTwoLevelInputChange}
              handleNestedInputChange={handleNestedInputChange}
              expandedSections={expandedSections}
              errors={errors}
            />
          </>
        )}
        {currentPage === 5 && (
          <>
            <SectionHeader title="Business Details" section="business" />

            <BusinessDetails
              formData={formData}
              handleTwoLevelInputChange={handleTwoLevelInputChange}
              expandedSections={expandedSections}
              errors={errors}
            />
          </>
        )}

        {/* Pagination controls */}
        <div className="p-4 flex justify-between">
          <button
            type="button"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition disabled:bg-gray-400"
          >
            Previous
          </button>
          <button
            type="button"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === 5}
            className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition disabled:bg-gray-400"
          >
            Next
          </button>
        </div>

        {/* Submit Button */}
        {currentPage === 5 && (
          <div className="p-4">
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition"
            >
              Submit Survey
            </button>
          </div>
        )}
      </form>
    </div>
  );
};
export default BeneficiaryForm;
