import React from 'react';

const WorkDetails = ({ formData, handleInputChange, expandedSections, errors }) => {
  const agriculturalSectors = [
    { value: "Krishi/Bali", label: "Krishi/Bali" },
    { value: "Pasupanchi", label: "Pasupanchi" },
    { value: "Mishrit", label: "Mishrit" },
  ];

  return (
    <div className={`p-4 ${expandedSections.workDetails ? "block" : "hidden"}`}>
      {/* Agricultural Sector */}
      <fieldset className="mb-4">
        <legend className="text-lg font-semibold mb-2">Agricultural Sector</legend>
        <div className="grid md:grid-cols-3 gap-2">
          {agriculturalSectors.map((sector) => (
            <label key={sector.value} className="block text-sm">
              <input
                type="radio"
                name="agriculturalSector"
                value={sector.value}
                checked={formData.agriculturalSector === sector.value}
                onChange={handleInputChange}
                className="mr-2"
              />
              {sector.label}
            </label>
          ))}
        </div>
        {errors?.agriculturalSector && (
          <p className="text-red-500 text-sm mt-1">{errors.agriculturalSector}</p>
        )}
      </fieldset>

      {/* Years in Field */}
      {formData.agriculturalSector && (
        <div className="mt-4">
          <label className="block text-sm font-medium">Years in Field</label>
          <input
            type="number"
            name="yearsInField"
            value={formData.yearsInField || ""}
            onChange={handleInputChange}
            className={`mt-1 block w-full border rounded-md p-2 ${
              errors?.yearsInField ? "border-red-500" : ""
            }`}
            min="0"
          />
          {errors?.yearsInField && (
            <p className="text-red-500 text-sm mt-1">{errors.yearsInField}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default WorkDetails;
