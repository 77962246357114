import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  MapPin,
  Users,
  Calendar,
  ChevronDown,
  Mail,
  Phone,
  ArrowRight,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import EmblemofNepal from "../asset/EmblemofNepal.png";
import Flag from "../asset/Flag.gif";
import Navbar from "../components/Navbar";

const GovLogo = () => (
  <div className="flex justify-center items-center my-4 animate-fadeIn">
    <img
      src={EmblemofNepal}
      alt="Emblem of Nepal"
      className="w-24 md:w-48 px-2 object-contain"
    />
  </div>
);

const FlagOfNepal = () => (
  <div className="flex justify-center items-center my-4 animate-fadeIn">
    <img
      src={Flag}
      alt="Flag of Nepal"
      className="w-16 md:w-28 pr-1 mb-2 md:mb-4 object-contain"
    />
  </div>
);

const StatCard = ({ title, value, icon: Icon }) => (
  <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2">
    <div className="flex items-center space-x-4">
      <div className="p-3 bg-blue-100 rounded-full">
        <Icon className="w-6 h-6 text-blue-600" />
      </div>
      <div>
        <p className="text-gray-600 text-sm">{title}</p>
        <p className="text-2xl font-bold text-gray-800">{value}</p>
      </div>
    </div>
  </div>
);

const StageCard = ({ stage, title, description, startDate, completion }) => (
  <div className="bg-white rounded-2xl shadow-lg overflow-hidden group hover:shadow-2xl transition-all duration-300">
    <div className="h-2 bg-blue-600" />
    <div className="p-8">
      <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-6">
        <span className="text-blue-600 font-bold">{stage}</span>
      </div>
      <h3 className="text-2xl font-bold mb-4 text-gray-800">{title}</h3>
      <p className="text-gray-600 leading-relaxed mb-4">{description}</p>
      <div className="space-y-2">
        <div className="text-sm text-gray-500">Started: {startDate}</div>
        <div className="text-sm text-gray-500">Completion: {completion}</div>
      </div>
    </div>
  </div>
);

const App = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/login");
  };

  return (
    <div className="bg-gray-50">
      <div className="bg-gradient-to-r from-blue-600 via-blue-700 to-blue-800 shadow-2xl overflow-hidden">
        <div className="container mx-auto">
          <div className="py-4 md:py-6">
            <div className="flex items-center justify-between px-2 md:px-8">
              <GovLogo />
              <div className="flex-grow mx-1 md:mx-8">
                <div>
                  <h2 className="text-md md:text-4xl font-bold text-white animate-fadeInLeft">
                    Mahakali Irrigation Project
                  </h2>
                  <p className="text-sm md:text-xl text-blue-100 mt-2 animate-fadeInLeft">
                    Management System
                  </p>
                  <div className="flex items-center md:space-x-2 bg-white/10 w-fit px-4 py-2 rounded-full mt-3">
                    <MapPin className="w-4 h-4 text-blue-200" />
                    <span className="text-blue-50 md:text-sm text-xs">
                      Kanchanpur, Nepal
                    </span>
                  </div>
                </div>
              </div>
              <FlagOfNepal />
            </div>
          </div>
        </div>
      </div>

      <Navbar />

      {/* Enhanced Carousel */}
      <div className="container mx-auto my-8 px-4">
        <div className="relative overflow-hidden rounded-2xl shadow-2xl">
          <Carousel
            autoPlay
            infiniteLoop
            showThumbs={false}
            showStatus={false}
            showIndicators={true}
            interval={5000}
            stopOnHover={true}
            className="rounded-2xl"
            swipeable={true}
          >
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-600/50 to-transparent z-10" />
              <img
                src="https://via.placeholder.com/1200x600/3B82F6/FFFFFF?text=Modern+Irrigation"
                alt="Modern Irrigation Systems"
                className="object-cover h-[500px] w-full"
              />
              <div className="absolute bottom-8 left-8 z-20 max-w-lg">
                <h3 className="text-3xl font-bold text-white mb-4">
                  Modern Irrigation Systems
                </h3>
                <p className="text-white text-lg">
                  Implementing state-of-the-art irrigation technology for
                  sustainable agriculture
                </p>
              </div>
            </div>
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600/50 to-transparent z-10" />
              <img
                src="https://via.placeholder.com/1200x600/10B981/FFFFFF?text=Sustainable+Agriculture"
                alt="Sustainable Agriculture"
                className="object-cover h-[500px] w-full"
              />
              <div className="absolute bottom-8 left-8 z-20 max-w-lg">
                <h3 className="text-3xl font-bold text-white mb-4">
                  Sustainable Agriculture
                </h3>
                <p className="text-white text-lg">
                  Supporting farmers with efficient water management systems
                </p>
              </div>
            </div>
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-red-600/50 to-transparent z-10" />
              <img
                src="https://via.placeholder.com/1200x600/EF4444/FFFFFF?text=Community+Impact"
                alt="Community Impact"
                className="object-cover h-[500px] w-full"
              />
              <div className="absolute bottom-8 left-8 z-20 max-w-lg">
                <h3 className="text-3xl font-bold text-white mb-4">
                  Community Impact
                </h3>
                <p className="text-white text-lg">
                  Transforming lives through improved agricultural
                  infrastructure
                </p>
              </div>
            </div>
          </Carousel>
        </div>
      </div>

      {/* Quick Stats Section */}
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          <StatCard
            title="Total Area Coverage"
            value="3,400 hectares"
            icon={MapPin}
          />
          <StatCard title="Beneficiary Farmers" value="12,000+" icon={Users} />
          <StatCard
            title="Project Timeline"
            value="Since 1975"
            icon={Calendar}
          />
          <StatCard title="Completion Rate" value="85%" icon={ChevronDown} />
        </div>
      </div>

      {/* Welcome Section */}
      <div className="container mx-auto py-16 px-4 text-center">
        <h2 className="text-4xl font-bold mb-6 animate-fadeInDown">
          Welcome to the Mahakali Irrigation Project
        </h2>
        <p className="text-lg text-gray-700 mb-8 animate-fadeInDown max-w-4xl mx-auto">
          The Mahakali Irrigation Project stands as a cornerstone of
          agricultural development in Nepal, providing essential irrigation
          infrastructure to support sustainable farming practices and improve
          agricultural productivity in the region.
        </p>
        <button
          onClick={handleRedirect}
          className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-full transition-all duration-300 transform hover:scale-105 animate-fadeInUp flex items-center mx-auto space-x-2"
        >
          <span>Access Management System</span>
          <ArrowRight className="w-4 h-4" />
        </button>
      </div>

      {/* Project Stages Section */}
      <div className="container mx-auto py-16 px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-blue-600 to-blue-800 bg-clip-text text-transparent">
            Project Development Stages
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Tracking our journey through systematic development and
            implementation
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <StageCard
            stage="1"
            title="Initial Development"
            description="Constructed in 1975, covering a command area of 3,400 hectares. Funded by the International Development Association, this phase established the core infrastructure."
            startDate="1975"
            completion="1988"
          />
          <StageCard
            stage="2"
            title="Expansion Phase"
            description="Signed in 1988 and completed in 1997, this phase focused on extending irrigation capabilities and improving water distribution systems."
            startDate="1988"
            completion="1997"
          />
          <StageCard
            stage="3"
            title="Modern Enhancement"
            description="Initiated in 2006, includes a 151 km long canal, with 28.5 km completed as of 2023. Part of the Mahakali treaty between Nepal and India."
            startDate="2006"
            completion="Ongoing"
          />
        </div>
      </div>

      {/* Enhanced Footer */}
      <footer className="bg-gradient-to-r from-gray-800 to-gray-900 text-gray-200 py-12">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 md:justify-items-center gap-12">
            <div>
              <h4 className="text-xl font-bold mb-6 text-white">Quick Links</h4>
              <ul className="space-y-4">
                {[
                  "Ministry of Energy, Water Resources and Irrigation",
                  "Department of Water Resources and Irrigation",
                  "Department of Hydrology and Meteorology",
                  "Water Resource Research and Development Centre",
                ].map((link, index) => (
                  <li key={index}>
                    <a
                      href="https://irrigation-beneficiary-management.pages.dev/"
                      className="flex items-center space-x-2 text-gray-300 hover:text-white transition-colors"
                    >
                      <ArrowRight className="w-4 h-4" />
                      <span>{link}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h4 className="text-xl font-bold mb-6 text-white">Contact Us</h4>
              <div className="space-y-4">
                <p className="flex items-center space-x-3">
                  <MapPin className="w-5 h-5 text-blue-400" />
                  <span>Kanchanpur, Nepal</span>
                </p>
                <div className="flex items-center space-x-3">
                  <Mail className="w-5 h-5 text-blue-400" />
                  <a
                    href="mailto:[mail]@gmail.com"
                    className="text-gray-300 hover:text-white transition-colors"
                  >
                    [mail]@gmail.com
                  </a>
                </div>
                <div className="flex items-center space-x-3">
                  <Phone className="w-5 h-5 text-blue-400" />
                  <a
                    href="tel:+977-00000000"
                    className="text-gray-300 hover:text-white transition-colors"
                  >
                    +977-[00000000]
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 pt-8 border-t border-gray-700 text-center">
            <p className="text-gray-400">
              © 2024 Mahakali Irrigation Project. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;
