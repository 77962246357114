import React from "react";
import {
  MapPin,
  Mail,
  Phone,
  ArrowRight,
  Book,
  Calendar,
  Target,
} from "lucide-react";
import Navbar from "../components/Navbar";
import EmblemofNepal from "../asset/EmblemofNepal.png";
import Flag from "../asset/Flag.gif";

const GovLogo = () => (
  <div className="flex justify-center items-center min-w-[120px] md:min-w-[180px]">
    <img
      src={EmblemofNepal}
      alt="Emblem of Nepal"
      className="w-20 md:w-32 object-contain"
    />
  </div>
);

const FlagOfNepal = () => (
  <div className="flex justify-center items-center min-w-[80px] md:min-w-[120px]">
    <img
      src={Flag}
      alt="Flag of Nepal"
      className="w-14 md:w-24 object-contain"
    />
  </div>
);

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow duration-300">
    <div className="flex items-start space-x-4">
      <div className="p-3 bg-blue-50 rounded-lg">
        <Icon className="w-6 h-6 text-blue-600" />
      </div>
      <div>
        <h4 className="text-lg font-semibold text-gray-800 mb-2">{title}</h4>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  </div>
);

const About = () => {
  return (
    <div className="bg-gray-50">
      <header className="bg-gradient-to-r from-blue-600 via-blue-700 to-blue-800 shadow-2xl overflow-hidden">
        <div className="container mx-auto">
          <div className="py-4 md:py-6">
            <div className="flex items-center justify-between px-2 md:px-8">
              <GovLogo />
              <div className="flex-grow mx-1 md:mx-8">
                <div>
                  <h2 className="text-md md:text-4xl font-bold text-white">
                    About Mahakali Irrigation Project
                  </h2>
                  <p className="text-sm md:text-xl text-blue-100 mt-2">
                    A National Pride Project of Nepal
                  </p>
                </div>
              </div>
              <FlagOfNepal />
            </div>
          </div>
        </div>
      </header>

      <Navbar />

      {/* Hero Section */}
      <div className="bg-gradient-to-b from-blue-50 to-white py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl md:text-5xl font-bold text-center text-gray-800 mb-6">
            Transforming Agriculture Through Innovation
          </h2>
          <p className="text-lg md:text-xl text-gray-600 max-w-4xl mx-auto text-center leading-relaxed">
            The Mahakali Irrigation Project stands as a testament to Nepal's
            commitment to agricultural advancement and sustainable water
            management.
          </p>
        </div>
      </div>

      {/* Overview Section */}
      <div className="container mx-auto py-16 px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div className="space-y-6">
            <div className="inline-block bg-blue-100 px-4 py-2 rounded-full">
              <span className="text-blue-600 font-semibold">
                Project Overview
              </span>
            </div>
            <h3 className="text-3xl font-bold text-gray-800">
              Empowering Farmers, Enriching Agriculture
            </h3>
            <p className="text-gray-600 leading-relaxed">
              The Mahakali Irrigation Project is a significant initiative aimed
              at enhancing agricultural productivity in Nepal. It diverts water
              from the Mahakali River at the Sarada Barrage to irrigate
              farmlands in Nepal under the Mahakali Treaty.
            </p>
            <div className="grid grid-cols-2 gap-4 pt-4">
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="text-2xl font-bold text-blue-600">3,400</div>
                <div className="text-sm text-gray-600">Hectares Coverage</div>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <div className="text-2xl font-bold text-blue-600">151 km</div>
                <div className="text-sm text-gray-600">Canal Length</div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-6">
            <FeatureCard
              icon={Book}
              title="Historical Development"
              description="Initiated in 1975, the project has evolved through multiple stages of development and modernization."
            />
            <FeatureCard
              icon={Calendar}
              title="Project Timeline"
              description="Three major stages of development from 1975 to present, with continuous improvements and expansions."
            />
            <FeatureCard
              icon={Target}
              title="Impact"
              description="Supporting thousands of farmers and fostering sustainable agriculture in Kanchanpur and Kailali districts."
            />
          </div>
        </div>
      </div>

      {/* Timeline Section */}
      <div className="bg-gray-50 py-16">
        <div className="container mx-auto px-4">
          <h3 className="text-3xl font-bold text-center mb-12">
            Project Development Timeline
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-xl shadow-lg">
              <div className="text-blue-600 font-bold text-lg mb-2">
                Stage 1: 1975-1988
              </div>
              <p className="text-gray-600">
                Initial development funded by the International Development
                Association, establishing core infrastructure.
              </p>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-lg">
              <div className="text-blue-600 font-bold text-lg mb-2">
                Stage 2: 1988-1997
              </div>
              <p className="text-gray-600">
                Extension of canal network and enhancement of water distribution
                systems.
              </p>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-lg">
              <div className="text-blue-600 font-bold text-lg mb-2">
                Stage 3: 2006-Present
              </div>
              <p className="text-gray-600">
                Modern enhancements including 151 km canal system, with 28.5 km
                completed as of 2023.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Enhanced Footer */}
      <footer className="bg-gradient-to-r from-gray-800 to-gray-900 text-gray-200 py-12">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-24">
            <div>
              <h4 className="text-xl font-bold mb-6 text-white">Quick Links</h4>
              <ul className="space-y-4">
                {[
                  "Ministry of Energy, Water Resources and Irrigation",
                  "Department of Water Resources and Irrigation",
                  "Department of Hydrology and Meteorology",
                  "Water Resource Research and Development Centre",
                ].map((link, index) => (
                  <li key={index}>
                    <a
                      href="https://irrigation-beneficiary-management.pages.dev/"
                      className="flex items-center space-x-2 text-gray-300 hover:text-white transition-colors group"
                    >
                      <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
                      <span>{link}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h4 className="text-xl font-bold mb-6 text-white">
                Contact Information
              </h4>
              <div className="space-y-4">
                <div className="flex items-center space-x-3 group">
                  <div className="p-2 bg-gray-700 rounded-lg group-hover:bg-gray-600 transition-colors">
                    <MapPin className="w-5 h-5 text-blue-400" />
                  </div>
                  <span>Kanchanpur, Nepal</span>
                </div>
                <div className="flex items-center space-x-3 group">
                  <div className="p-2 bg-gray-700 rounded-lg group-hover:bg-gray-600 transition-colors">
                    <Mail className="w-5 h-5 text-blue-400" />
                  </div>
                  <a
                    href="mailto:[mail]@gmail.com"
                    className="hover:text-white transition-colors"
                  >
                    [mail]@gmail.com
                  </a>
                </div>
                <div className="flex items-center space-x-3 group">
                  <div className="p-2 bg-gray-700 rounded-lg group-hover:bg-gray-600 transition-colors">
                    <Phone className="w-5 h-5 text-blue-400" />
                  </div>
                  <a
                    href="tel:+977-00000000"
                    className="hover:text-white transition-colors"
                  >
                    +977-[00000000]
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 pt-8 border-t border-gray-700 text-center">
            <p className="text-gray-400">
              © 2024 Mahakali Irrigation Project. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default About;
