import React from "react";

const Education = ({ formData, handleInputChange, expandedSections, errors }) => {
  return (
    <div className={`p-4 ${expandedSections.education ? "block" : "hidden"}`}>
      <div className="space-y-4">
        {/* Literacy Status */}
        <div>
          <label className="block text-sm font-medium">Literacy Status</label>
          <div className="flex space-x-4">
            {["Literate", "Illiterate"].map((status) => (
              <label key={status} className="inline-flex items-center">
                <input
                  type="radio"
                  name="literacyStatus"
                  value={status}
                  checked={formData.literacyStatus === status}
                  onChange={(e) => {
                    handleInputChange(e);
                    if (e.target.value === "Illiterate") {
                      handleInputChange({
                        target: { name: "literacyLevel", value: "" },
                      });
                    }
                  }}
                  className={`form-radio ${
                    errors.literacyStatus ? "border-red-500" : ""
                  }`}
                />
                <span className="ml-2">{status}</span>
              </label>
            ))}
          </div>
          {errors.literacyStatus && (
            <p className="text-red-500 text-sm mt-1">{errors.literacyStatus}</p>
          )}
        </div>

        {/* Conditional Literacy Level Selection */}
        {formData.literacyStatus === "Literate" && (
          <div>
            <label className="block text-sm font-medium">Literacy Level</label>
            <select
              name="literacyLevel"
              value={formData.literacyLevel}
              onChange={handleInputChange}
              className={`mt-1 block w-full border rounded-md p-2 ${
                errors.literacyLevel ? "border-red-500" : ""
              }`}
            >
              <option value="">Select Level</option>
              <option value="Primary">Primary</option>
              <option value="Secondary">Secondary</option>
              <option value="Higher">Higher Education</option>
            </select>
            {errors.literacyLevel && (
              <p className="text-red-500 text-sm mt-1">{errors.literacyLevel}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Education;
