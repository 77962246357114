import React from 'react';

const FinancialInfo = ({
  formData,
  setFormData,
  expandedSections,
  errors // Add this line to accept errors prop
}) => {
  // Function to handle changes for radio buttons 
  // had to define separate
  const handleRadioboxChange = (e, section, key) => {
    const { value } = e.target;

    setFormData((prevState) => {
      return {
        ...prevState,
        [section]: {
          ...prevState[section],
          [key]: value,
        },
      };
    });
  };

  return (
    <div className={`p-4 ${expandedSections.financialInfo ? "block" : "hidden"}`}>
      <div className="mb-6">
        <h3 className="text-sm font-medium mb-2">Agricultural Loans:</h3>
        <div className="flex flex-col space-y-3">
          <div>
            <label className="block text-sm font-medium">Taken?</label>
            <div className="flex space-x-4">
              <label>
                <input
                  type="radio"
                  name="agriculturalLoansTaken"
                  value="yes"
                  checked={formData.agriculturalLoans.taken === "yes"}
                  onChange={(e) =>
                    handleRadioboxChange(e, "agriculturalLoans", "taken")
                  }
                  className="mr-2"
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="agriculturalLoansTaken"
                  value="no"
                  checked={formData.agriculturalLoans.taken === "no"}
                  onChange={(e) =>
                    handleRadioboxChange(e, "agriculturalLoans", "taken")
                  }
                  className="mr-2"
                />{" "}
                No
              </label>
            </div>
            {errors.agriculturalLoansTaken && (
              <p className="text-red-500 text-sm mt-1">{errors.agriculturalLoansTaken}</p>
            )}
          </div>

          {formData.agriculturalLoans.taken === "yes" && (
            <div>
              <div>
                <label className="block text-sm font-medium">Source</label>
                <select
                  name="agriculturalLoansSource"
                  value={formData.agriculturalLoans.source}
                  onChange={(e) =>
                    handleRadioboxChange(e, "agriculturalLoans", "source")
                  }
                  className={`mt-1 block w-full border rounded-md p-2 ${errors.agriculturalLoansSource ? "border-red-500" : ""}`}
                >
                  <option value="bank">Bank</option>
                  <option value="cooperative">Cooperative</option>
                  <option value="other">Other</option>
                </select>
                {errors.agriculturalLoansSource && (
                  <p className="text-red-500 text-sm mt-1">{errors.agriculturalLoansSource}</p>
                )}
              </div>

              <div className="mt-4">
                <label className="block text-sm font-medium">Amount</label>
                <input
                  type="number"
                  name="agriculturalLoansAmount"
                  value={formData.agriculturalLoans.amount}
                  onChange={(e) =>
                    handleRadioboxChange(e, "agriculturalLoans", "amount")
                  }
                  className={`mt-1 block w-full border rounded-md p-2 ${errors.agriculturalLoansAmount ? "border-red-500" : ""}`}
                  placeholder="Enter loan amount"
                />
                {errors.agriculturalLoansAmount && (
                  <p className="text-red-500 text-sm mt-1">{errors.agriculturalLoansAmount}</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mb-6">
        <h3 className="text-sm font-medium mb-2">Agricultural Insurance:</h3>
        <div className="flex flex-col space-y-3">
          <div>
            <label className="block text-sm font-medium">Enrolled?</label>
            <div className="flex space-x-4">
              <label>
                <input
                  type="radio"
                  name="agriculturalInsuranceEnrolled"
                  value="yes"
                  checked={formData.agriculturalInsurance.enrolled === "yes"}
                  onChange={(e) =>
                    handleRadioboxChange(e, "agriculturalInsurance", "enrolled")
                  }
                  className="mr-2"
                />{" "}
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="agriculturalInsuranceEnrolled"
                  value="no"
                  checked={formData.agriculturalInsurance.enrolled === "no"}
                  onChange={(e) =>
                    handleRadioboxChange(e, "agriculturalInsurance", "enrolled")
                  }
                  className="mr-2"
                />{" "}
                No
              </label>
            </div>
            {errors.agriculturalInsuranceEnrolled && (
              <p className="text-red-500 text-sm mt-1">{errors.agriculturalInsuranceEnrolled}</p>
            )}
          </div>

          {formData.agriculturalInsurance.enrolled === "yes" && (
            <div>
              <div>
                <label className="block text-sm font-medium">Insured Items</label>
                <textarea
                  name="agriculturalInsuranceInsuredItems"
                  value={formData.agriculturalInsurance.insuredItems}
                  onChange={(e) =>
                    handleRadioboxChange(
                      e,
                      "agriculturalInsurance",
                      "insuredItems"
                    )
                  }
                  className={`mt-1 block w-full border rounded-md p-2 ${errors.agriculturalInsuranceInsuredItems ? "border-red-500" : ""}`}
                  placeholder="Enter details of insured crops or livestock"
                />
                {errors.agriculturalInsuranceInsuredItems && (
                  <p className="text-red-500 text-sm mt-1">{errors.agriculturalInsuranceInsuredItems}</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FinancialInfo;