import React from "react";
import {
  MapPin,
  Mail,
  Phone,
  MessageSquare,
  User,
  Building,
  Send,
} from "lucide-react";
import Navbar from "../components/Navbar";
import EmblemofNepal from "../asset/EmblemofNepal.png";
import Flag from "../asset/Flag.gif";

const GovLogo = () => (
  <div className="flex justify-center items-center min-w-[120px] md:min-w-[180px]">
    <img
      src={EmblemofNepal}
      alt="Emblem of Nepal"
      className="w-20 md:w-32 object-contain"
    />
  </div>
);

const FlagOfNepal = () => (
  <div className="flex justify-center items-center min-w-[80px] md:min-w-[120px]">
    <img
      src={Flag}
      alt="Flag of Nepal"
      className="w-14 md:w-24 object-contain"
    />
  </div>
);

const ContactInfoCard = ({ icon: Icon, title, content, href }) => (
  <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all">
    <div className="flex items-center space-x-4">
      <div className="p-3 bg-blue-50 rounded-lg">
        <Icon className="w-6 h-6 text-blue-600" />
      </div>
      <div>
        <h4 className="text-lg font-semibold text-gray-800 mb-1">{title}</h4>
        {href ? (
          <a
            href={href}
            className="text-gray-600 hover:text-blue-600 transition-colors"
          >
            {content}
          </a>
        ) : (
          <p className="text-gray-600">{content}</p>
        )}
      </div>
    </div>
  </div>
);

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <header className="bg-gradient-to-r from-blue-600 via-blue-700 to-blue-800 shadow-2xl overflow-hidden">
        <div className="container mx-auto">
          <div className="py-4 md:py-6">
            <div className="flex items-center justify-between px-2 md:px-8">
              <GovLogo />
              <div className="flex-grow mx-1 md:mx-8">
                <div>
                  <h2 className="text-md md:text-4xl font-bold text-white">
                    Contact Mahakali Irrigation Project
                  </h2>
                  <p className="text-sm md:text-xl text-blue-100 mt-2">
                    We're here to help and answer any questions
                  </p>
                </div>
              </div>
              <FlagOfNepal />
            </div>
          </div>
        </div>
      </header>

      <Navbar />

      <div className="container mx-auto px-4 py-16">
        <div className="max-w-7xl mx-auto">
          {/* Contact Information Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-16">
            <ContactInfoCard
              icon={MapPin}
              title="Our Location"
              content="Kanchanpur, Nepal"
            />
            <ContactInfoCard
              icon={Phone}
              title="Phone Number"
              content="+977-[00000000]"
              href="tel:+977-00000000"
            />
            <ContactInfoCard
              icon={Mail}
              title="Email Address"
              content="[mail]@gmail.com"
              href="mailto:[mail]@gmail.com"
            />
          </div>

          {/* Contact Form Section */}
          <div className="bg-white rounded-2xl shadow-xl p-6 md:p-10">
            <div className="max-w-3xl mx-auto">
              <h3 className="text-2xl md:text-3xl font-bold text-gray-800 mb-8 text-center">
                Send Us a Message
              </h3>

              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700">
                      Full Name
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <User className="h-5 w-5 text-gray-400" />
                      </div>
                      <input
                        type="text"
                        className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                        placeholder="John Doe"
                        required
                      />
                    </div>
                  </div>

                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700">
                      Organization
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Building className="h-5 w-5 text-gray-400" />
                      </div>
                      <input
                        type="text"
                        className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                        placeholder="Your Organization"
                      />
                    </div>
                  </div>
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">
                    Email Address
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Mail className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="email"
                      className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                      placeholder="your@email.com"
                      required
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700">
                    Message
                  </label>
                  <div className="relative">
                    <div className="absolute top-3 left-3 pointer-events-none">
                      <MessageSquare className="h-5 w-5 text-gray-400" />
                    </div>
                    <textarea
                      className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-600 focus:border-transparent min-h-[150px]"
                      placeholder="Your message here..."
                      required
                    ></textarea>
                  </div>
                </div>

                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors space-x-2"
                  >
                    <Send className="w-5 h-5" />
                    <span>Send Message</span>
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* Map Section */}
          <div className="mt-16 bg-white rounded-2xl shadow-xl p-6">
            <h3 className="text-2xl font-bold text-gray-800 mb-6 text-center">
              Our Location
            </h3>
            <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
              <div className="w-full h-[400px] bg-gray-200 rounded-lg flex items-center justify-center">
                <p className="text-gray-500">Map placeholder</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gradient-to-r from-gray-800 to-gray-900 text-gray-200 py-12 mt-16">
        <div className="container mx-auto px-4">
          <div className="text-center">
            <p className="text-gray-400">
              © 2024 Mahakali Irrigation Project. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Contact;
