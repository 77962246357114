import React from 'react';

const AddressField = ({ label, value, onChange, name, error }) => (
  <div>
    <label className="block text-sm font-medium">{label}</label>
    <input
      type="text"
      name={name}
      value={value}
      onChange={onChange}
      className={`mt-1 block w-full border rounded-md p-2 ${
        error ? "border-red-500" : ""
      }`}
    />
    {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
  </div>
);

export default AddressField;
