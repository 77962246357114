import React from 'react';

const LivestockDetails = ({
  formData,
  handleInputChange,
  handleCheckboxChange,
  handleNestedInputChange,
  expandedSections,
  errors 
}) => {
  const livestockTypes = [
    "goat",
    "buffalo",
    "poultry",
    "fish",
    "cow",
    "OxBull",
    "Pig"
  ];

  return (
    <div className={`p-4 ${expandedSections.livestock ? "block" : "hidden"}`}>
      <div className="mb-4">
        <label className="block text-sm font-medium">Types of Livestock Owned</label>
        <div className="flex items-center space-x-4">
          {livestockTypes.map((type) => (
            <label key={type}>
              <input
                type="checkbox"
                name="livestockOwned"
                value={type}
                checked={formData.livestockOwned.includes(type)}
                onChange={(e) => handleCheckboxChange(e, "livestockOwned")}
                className="mr-2"
              />
              {type.charAt(0).toUpperCase() + type.slice(1)} {/* Capitalize */}
            </label>
          ))}
        </div>
        {errors.livestockOwned && (
          <p className="text-red-500 text-sm mt-1">{errors.livestockOwned}</p>
        )}
      </div>

      {formData.livestockOwned.length > 0 && (
        <div>
          {formData.livestockOwned.map((livestock) => (
            <div key={livestock} className="mb-4">
              <h3 className="text-sm font-medium">{livestock} Details</h3>
              <div className="grid grid-cols-2 gap-4">
                {livestock === "cow" || livestock === "buffalo" ? (
                  <>
                    <div>
                      <label className="block text-sm font-medium">Number of {livestock}s</label>
                      <input
                        type="number"
                        name={`livestockProduction[${livestock}].number`}
                        value={formData.livestockProduction[livestock]?.number || ""}
                        onChange={(e) =>
                          handleNestedInputChange("livestockProduction", livestock, "number", e.target.value)
                        }
                        className={`mt-1 block w-full border rounded-md p-2 ${errors[`livestockProduction.${livestock}.number`] ? "border-red-500" : ""}`}
                        placeholder={`Enter number of ${livestock}s`}
                      />
                      {errors[`livestockProduction.${livestock}.number`] && (
                        <p className="text-red-500 text-sm mt-1">{errors[`livestockProduction.${livestock}.number`]}</p>
                      )}
                    </div>
                    <div>
                      <label className="block text-sm font-medium">Months of Milk Production</label>
                      <input
                        type="number"
                        name={`${livestock}_milkMonths`}
                        value={formData.livestockProduction[livestock]?.milkMonths || ""}
                        onChange={(e) =>
                          handleNestedInputChange("livestockProduction", livestock, "milkMonths", e.target.value)
                        }
                        className={`mt-1 block w-full border rounded-md p-2 ${errors[`livestockProduction.${livestock}.milkMonths`] ? "border-red-500" : ""}`}
                        placeholder="Enter number of months"
                      />
                      {errors[`livestockProduction.${livestock}.milkMonths`] && (
                        <p className="text-red-500 text-sm mt-1">{errors[`livestockProduction.${livestock}.milkMonths`]}</p>
                      )}
                    </div>
                    <div>
                      <label className="block text-sm font-medium">Milk per Day (liters)</label>
                      <input
                        type="number"
                        name={`${livestock}_milkPerDay`}
                        value={formData.livestockProduction[livestock]?.milkPerDay || ""}
                        onChange={(e) =>
                          handleNestedInputChange("livestockProduction", livestock, "milkPerDay", e.target.value)
                        }
                        className={`mt-1 block w-full border rounded-md p-2 ${errors[`livestockProduction.${livestock}.milkPerDay`] ? "border-red-500" : ""}`}
                        placeholder="Enter milk per day"
                      />
                      {errors[`livestockProduction.${livestock}.milkPerDay`] && (
                        <p className="text-red-500 text-sm mt-1">{errors[`livestockProduction.${livestock}.milkPerDay`]}</p>
                      )}
                    </div>
                  </>
                ) : livestock === "OxBull" ? (
                  <div>
                    <label className="block text-sm font-medium">Number of Ox/Bulls</label>
                    <input
                      type="number"
                      name={`${livestock}_number`}
                      value={formData.livestockProduction[livestock]?.number || ""}
                      onChange={(e) =>
                        handleNestedInputChange("livestockProduction", livestock, "number", e.target.value)
                      }
                      className={`mt-1 block w-full border rounded-md p-2 ${errors[`livestockProduction.${livestock}.number`] ? "border-red-500" : ""}`}
                      placeholder="Enter number of ox/bulls"
                    />
                    {errors[`livestockProduction.${livestock}.number`] && (
                      <p className="text-red-500 text-sm mt-1">{errors[`livestockProduction.${livestock}.number`]}</p>
                    )}
                  </div>
                ): livestock === "goat" ? (
                  ["mau", "khasi", "boka", "patha/pathi"].map((category) => (
                    <div key={category}>
                      <label className="block text-sm font-medium">Number of {category} ({livestock})</label>
                      <input
                        type="number"
                        name={`${livestock}_${category}`}
                        value={formData.livestockProduction[livestock]?.[category] || ""}
                        onChange={(e) =>
                          handleNestedInputChange("livestockProduction", livestock, category, e.target.value)
                        }
                        className={`mt-1 block w-full border rounded-md p-2 ${errors[`livestockProduction.${livestock}.${category}`] ? "border-red-500" : ""}`}
                        placeholder={`Enter number of ${category}`}
                      />
                      {errors[`livestockProduction.${livestock}.${category}`] && (
                        <p className="text-red-500 text-sm mt-1">{errors[`livestockProduction.${livestock}.${category}`]}</p>
                      )}
                    </div>
                  ))
                ) : livestock === "Pig" ? (
                  ["male", "female", "children"].map((category) => (
                    <div key={category}>
                      <label className="block text-sm font-medium">Number of {category} ({livestock})</label>
                      <input
                        type="number"
                        name={`${livestock}_${category}`}
                        value={formData.livestockProduction[livestock]?.[category] || ""}
                        onChange={(e) =>
                          handleNestedInputChange("livestockProduction", livestock, category, e.target.value)
                        }
                        className={`mt-1 block w-full border rounded-md p-2 ${errors[`livestockProduction.${livestock}.${category}`] ? "border-red-500" : ""}`}
                        placeholder={`Enter number of ${category}`}
                      />
                      {errors[`livestockProduction.${livestock}.${category}`] && (
                        <p className="text-red-500 text-sm mt-1">{errors[`livestockProduction.${livestock}.${category}`]}</p>
                      )}
                    </div>
                  ))
                ) : livestock === "poultry" ? (
                  ["mau", "khasi", "boka", "patha/pathi"].map((category) => (
                    <div key={category}>
                      <label className="block text-sm font-medium">Number of {category} ({livestock})</label>
                      <input
                        type="number"
                        name={`${livestock}_${category}`}
                        value={formData.livestockProduction[livestock]?.[category] || ""}
                        onChange={(e) =>
                          handleNestedInputChange("livestockProduction", livestock, category, e.target.value)
                        }
                        className={`mt-1 block w-full border rounded-md p-2 ${errors[`livestockProduction.${livestock}.${category}`] ? "border-red-500" : ""}`}
                        placeholder={`Enter number of ${category}`}
                      />
                      {errors[`livestockProduction.${livestock}.${category}`] && (
                        <p className="text-red-500 text-sm mt-1">{errors[`livestockProduction.${livestock}.${category}`]}</p>
                      )}
                    </div>
                  ))
                ) : livestock === "poultry" ? (
                  <>
                    <div>
                      <label className="block text-sm font-medium">Number of Poultry</label>
                      <input
                        type="number"
                        name={`${livestock}_number`}
                        value={formData.livestockProduction[livestock]?.number || ""}
                        onChange={(e) =>
                          handleNestedInputChange("livestockProduction", livestock, "number", e.target.value)
                        }
                        className={`mt-1 block w-full border rounded-md p-2 ${errors[`livestockProduction.${livestock}.number`] ? "border-red-500" : ""}`}
                        placeholder="Enter number of poultry"
                      />
                      {errors[`livestockProduction.${livestock}.number`] && (
                        <p className="text-red-500 text-sm mt-1">{errors[`livestockProduction.${livestock}.number`]}</p>
                      )}
                    </div>
                    <div>
                      <label className="block text-sm font-medium">Yearly Egg Production</label>
                      <input
                        type="number"
                        name={`${livestock}_eggs`}
                        value={formData.livestockProduction[livestock]?.eggs || ""}
                        onChange={(e) =>
                          handleNestedInputChange("livestockProduction", livestock, "eggs", e.target.value)
                        }
                        className={`mt-1 block w-full border rounded-md p-2 ${errors[`livestockProduction.${livestock}.eggs`] ? "border-red-500" : ""}`}
                        placeholder="Enter yearly egg production"
                      />
                      {errors[`livestockProduction.${livestock}.eggs`] && (
                        <p className="text-red-500 text-sm mt-1">{errors[`livestockProduction.${livestock}.eggs`]}</p>
                      )}
                    </div>
                    <div>
                      <label className="block text-sm font-medium">Yearly Poultry Sale</label>
                      <input
                        type="number"
                        name={`${livestock}_sale`}
                        value={formData.livestockProduction[livestock]?.sale || ""}
                        onChange={(e) =>
                          handleNestedInputChange("livestockProduction", livestock, "sale", e.target.value)
                        }
                        className={`mt-1 block w-full border rounded-md p-2 ${errors[`livestockProduction.${livestock}.sale`] ? "border-red-500" : ""}`}
                        placeholder="Enter yearly poultry sale"
                      />
                      {errors[`livestockProduction.${livestock}.sale`] && (
                        <p className="text-red-500 text-sm mt-1">{errors[`livestockProduction.${livestock}.sale`]}</p>
                      )}
                    </div>
                  </>
                ) : livestock === "fish" ? (
                  <>
                    <div>
                      <label className="block text-sm font-medium">Size of Pokhari (m²)</label>
                      <input
                        type="number"
                        name={`${livestock}_size`}
                        value={formData.livestockProduction[livestock]?.size || ""}
                        onChange={(e) =>
                          handleNestedInputChange("livestockProduction", livestock, "size", e.target.value)
                        }
                        className={`mt-1 block w-full border rounded-md p-2 ${errors[`livestockProduction.${livestock}.size`] ? "border-red-500" : ""}`}
                        placeholder="Enter size of pokhari"
                      />
                      {errors[`livestockProduction.${livestock}.size`] && (
                        <p className="text-red-500 text-sm mt-1">{errors[`livestockProduction.${livestock}.size`]}</p>
                      )}
                    </div>
                    <div>
                      <label className="block text-sm font-medium">Number of Fish</label>
                      <input
                        type="number"
                        name={`${livestock}_number`}
                        value={formData.livestockProduction[livestock]?.number || ""}
                        onChange={(e) =>
                          handleNestedInputChange("livestockProduction", livestock, "number", e.target.value)
                        }
                        className={`mt-1 block w-full border rounded-md p-2 ${errors[`livestockProduction.${livestock}.number`] ? "border-red-500" : ""}`}
                        placeholder="Enter number of fish"
                      />
                      {errors[`livestockProduction.${livestock}.number`] && (
                        <p className="text-red-500 text-sm mt-1">{errors[`livestockProduction.${livestock}.number`]}</p>
                      )}
                    </div>
                    <div>
                      <label className="block text-sm font-medium">Production (choose unit)</label>
                      <select
                        name={`${livestock}_unit`}
                        value={formData.livestockProduction[livestock]?.unit || ""}
                        onChange={(e) =>
                          handleNestedInputChange("livestockProduction", livestock, "unit", e.target.value)
                        }
                        className={`mt-1 block w-full border rounded-md p-2 ${errors[`livestockProduction.${livestock}.unit`] ? "border-red-500" : ""}`}
                      >
                        <option value="kg">Kg</option>
                        <option value="number">Number</option>
                      </select>
                      {errors[`livestockProduction.${livestock}.unit`] && (
                        <p className="text-red-500 text-sm mt-1">{errors[`livestockProduction.${livestock}.unit`]}</p>
                      )}
                      <input
                        type="number"
                        name={`${livestock}_production`}
                        value={formData.livestockProduction[livestock]?.production || ""}
                        onChange={(e) =>
                          handleNestedInputChange("livestockProduction", livestock, "production", e.target.value)
                        }
                        className={`mt-1 block w-full border rounded-md p-2 ${errors[`livestockProduction.${livestock}.production`] ? "border-red-500" : ""}`}
                        placeholder="Enter production"
                      />
                      {errors[`livestockProduction.${livestock}.production`] && (
                        <p className="text-red-500 text-sm mt-1">{errors[`livestockProduction.${livestock}.production`]}</p>
                      )}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LivestockDetails;