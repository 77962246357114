import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Download,
  Edit,
  Trash2,
  Search,
  MapPin,
  Phone,
  Ruler,
  Droplet,
  LayoutGrid,
  User,
  AlertTriangle,
  Menu,
  X,
  Filter,
  CreditCard,
} from "lucide-react";
import * as XLSX from "xlsx";

const FarmerProfileManagement = () => {
  const navigate = useNavigate();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [selectedFarmer, setSelectedFarmer] = useState(null);

  const [farmers] = useState([
    {
      id: 1,
      name: "John Doe",
      address: "123 Rural Road, Village A",
      totalLand: 10.5,
      irrigatedLand: 7.2,
      block: "Block 1",
      contactNumber: "9876543210",
      district: "District X",
      ward: "Ward 5",
    },
    {
      id: 2,
      name: "Jane Smith",
      address: "456 Farmland Street, Village B",
      totalLand: 15.3,
      irrigatedLand: 12.1,
      block: "Block 2",
      contactNumber: "8765432109",
      district: "District Y",
      ward: "Ward 3",
    },
    ...Array.from({ length: 30 }, (_, i) => ({
      id: i + 3,
      name: `Farmer ${i + 3}`,
      address: `Address ${i + 3}`,
      totalLand: (i + 1) * 2,
      irrigatedLand: (i + 1) * 1.5,
      block: `Block ${(i % 3) + 1}`,
      contactNumber: `98765${(i + 3).toString().padStart(6, "0")}`,
      district: `District ${String.fromCharCode(88 + (i % 3))}`,
      ward: `Ward ${(i % 5) + 1}`,
    })),
  ]);

  // Add click outside handler
  const handleClickOutside = useCallback((event) => {
    if (!event.target.closest(".action-menu")) {
      setOpenMenuId(null);
    }
  }, []);

  // Add event listener for click outside
  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  const [filters, setFilters] = useState({
    district: "",
    ward: "",
    block: "",
    phoneNumber: "",
  });

  const [searchState, setSearchState] = useState({
    term: "",
    results: [],
    isSearching: false,
    error: null,
    searchDisabled: false,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filterOptions = useMemo(
    () => ({
      districts: [...new Set(farmers.map((f) => f.district))],
      wards: [...new Set(farmers.map((f) => f.ward))],
      blocks: [...new Set(farmers.map((f) => f.block))],
    }),
    [farmers]
  );

  const filteredFarmers = useMemo(() => {
    const dataToFilter =
      searchState.results.length > 0 ? searchState.results : farmers;
    return dataToFilter.filter((farmer) => {
      const matchesDistrict =
        !filters.district || farmer.district === filters.district;
      const matchesWard = !filters.ward || farmer.ward === filters.ward;
      const matchesBlock = !filters.block || farmer.block === filters.block;
      const matchesPhone =
        !filters.phoneNumber ||
        farmer.contactNumber.includes(filters.phoneNumber);
      //added for changing to front page when filter is applied as data will be less and it could lead to blank page when user is on back pages
      setCurrentPage(1);
      return matchesDistrict && matchesWard && matchesBlock && matchesPhone;
    });
  }, [farmers, searchState.results, filters]);

  const paginatedFarmers = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredFarmers.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredFarmers, currentPage]);

  const handleSearch = useCallback(async () => {
    if (searchState.searchDisabled || !searchState.term.trim()) return;
    setSearchState((prev) => ({
      ...prev,
      isSearching: true,
      searchDisabled: true,
      error: null,
    }));

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const results = farmers.filter((farmer) =>
        farmer.name.toLowerCase().includes(searchState.term.toLowerCase())
      );

      setSearchState((prev) => ({
        ...prev,
        results,
        isSearching: false,
        searchDisabled: false,
        error: results.length === 0 ? "No results found" : null,
      }));
    } catch (error) {
      setSearchState((prev) => ({
        ...prev,
        results: [],
        isSearching: false,
        searchDisabled: false,
        error: "Failed to search. Please try again.",
      }));
    }
  }, [farmers, searchState.term, searchState.searchDisabled]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") handleSearch();
  };

  const exportFarmerToExcel = (farmer) => {
    const worksheet = XLSX.utils.json_to_sheet([farmer]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Farmer");
    XLSX.writeFile(workbook, `farmer_${farmer.name}.xlsx`);
  };

  const totalPages = Math.ceil(filteredFarmers.length / itemsPerPage);

  const handleEditFarmer = (farmer) => {
    navigate(`/beneficiaryForm?phone=${farmer.contactNumber}`, {
      state: { farmerData: farmer, mode: "edit" },
    });
  };
  const handlePayment = (farmer) => {
    navigate(`/payment?phone=${farmer.contactNumber}`, {
      state: { farmerData: farmer },
    });
  };

  const ActionMenu = ({ farmer }) => (
    <div className="action-menu flex flex-col space-y-2 p-4 bg-white rounded-lg shadow-lg">
      <button
        onClick={() => {
          handleEditFarmer(farmer);
          setOpenMenuId(null);
        }}
        className="flex items-center space-x-2 text-blue-500 hover:bg-blue-50 p-2 rounded"
      >
        <Edit size={18} />
        <span>Edit</span>
      </button>
      <button
        onClick={() => {
          handlePayment(farmer);
          setOpenMenuId(null);
        }}
        className="flex items-center space-x-2 text-purple-500 hover:bg-purple-50 p-2 rounded"
      >
        <CreditCard size={18} />
        <span>Add Payment</span>
      </button>
      <button
        onClick={() => {
          exportFarmerToExcel(farmer);
          setOpenMenuId(null);
        }}
        className="flex items-center space-x-2 text-green-500 hover:bg-green-50 p-2 rounded"
      >
        <Download size={18} />
        <span>Export</span>
      </button>
      <button
        onClick={() => setOpenMenuId(null)}
        className="flex items-center space-x-2 text-red-500 hover:bg-red-50 p-2 rounded"
      >
        <Trash2 size={18} />
        <span>Delete</span>
      </button>
    </div>
  );

  const DetailRow = ({ icon, label, value }) => (
    <div className="flex items-center">
      {React.cloneElement(icon, { className: "mr-2 text-gray-600", size: 20 })}
      <span className="font-semibold mr-2">{label}:</span>
      <span>{value}</span>
    </div>
  );

  const FarmerDetailsModal = ({ farmer, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg p-6 w-full max-w-md max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">{farmer.name} Details</h2>
          <button onClick={onClose} className="p-1">
            <X size={24} />
          </button>
        </div>
        <div className="space-y-3">
          <DetailRow icon={<User />} label="Name" value={farmer.name} />
          <DetailRow icon={<MapPin />} label="Address" value={farmer.address} />
          <DetailRow
            icon={<Ruler />}
            label="Total Land"
            value={`${farmer.totalLand} acres`}
          />
          <DetailRow
            icon={<Droplet />}
            label="Irrigated Land"
            value={`${farmer.irrigatedLand} acres`}
          />
          <DetailRow icon={<LayoutGrid />} label="Block" value={farmer.block} />
          <DetailRow
            icon={<Phone />}
            label="Contact Number"
            value={farmer.contactNumber}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50 ">
      <div className="container mx-auto md:px-4">
        {/* Main Card */}
        <div className="bg-white shadow-lg md:rounded-xl overflow-hidden">
          {/* Header Section */}
          <div className="bg-gradient-to-r from-blue-600 to-blue-700 p-6 text-white">
            <div className="flex justify-between items-center">
              <button
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                className="md:hidden bg-white/20 p-2 rounded-lg hover:bg-white/30 transition-colors"
              >
                <Filter size={20} />
              </button>
            </div>

            {/* Search Bar */}
            <div className="mt-6">
              <div className="flex max-w-2xl mx-auto">
                <div className="relative flex-grow">
                  <input
                    type="text"
                    placeholder="Search by farmer name..."
                    value={searchState.term}
                    onChange={(e) =>
                      setSearchState((prev) => ({
                        ...prev,
                        term: e.target.value,
                        error: null,
                      }))
                    }
                    onKeyPress={handleKeyPress}
                    disabled={searchState.searchDisabled}
                    className="w-full pl-4 pr-10 py-3 text-black rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-300 border-0"
                  />
                  {searchState.term && (
                    <button
                      onClick={() =>
                        setSearchState((prev) => ({
                          ...prev,
                          term: "",
                          results: [],
                        }))
                      }
                      className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                    >
                      <X size={16} />
                    </button>
                  )}
                </div>
                <button
                  onClick={handleSearch}
                  disabled={
                    searchState.searchDisabled || !searchState.term.trim()
                  }
                  className={`
                    px-6 py-3 rounded-r-lg flex items-center font-medium
                    ${
                      searchState.searchDisabled || !searchState.term.trim()
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-blue-900 hover:bg-blue-800 text-white"
                    }
                    transition-colors duration-200
                  `}
                >
                  <Search size={18} className="mr-2" />
                  <span className="hidden md:inline">Search</span>
                </button>
              </div>
            </div>
          </div>

          {/* Filters Section */}
          <div
            className={`
              border-b bg-white/50 backdrop-blur-sm
              ${isFilterOpen ? "block" : "hidden md:block"}
              transition-all duration-200
            `}
          >
            <div className="p-6">
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-600 block">
                    District
                  </label>
                  <select
                    value={filters.district}
                    onChange={(e) =>
                      setFilters((prev) => ({
                        ...prev,
                        district: e.target.value,
                      }))
                    }
                    className="w-full border border-gray-200 rounded-lg p-2.5 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">All Districts</option>
                    {filterOptions.districts.map((district) => (
                      <option key={district} value={district}>
                        {district}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-600 block">
                    Ward
                  </label>
                  <select
                    value={filters.ward}
                    onChange={(e) =>
                      setFilters((prev) => ({ ...prev, ward: e.target.value }))
                    }
                    className="w-full border border-gray-200 rounded-lg p-2.5 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">All Wards</option>
                    {filterOptions.wards.map((ward) => (
                      <option key={ward} value={ward}>
                        {ward}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-600 block">
                    Block
                  </label>
                  <select
                    value={filters.block}
                    onChange={(e) =>
                      setFilters((prev) => ({ ...prev, block: e.target.value }))
                    }
                    className="w-full border border-gray-200 rounded-lg p-2.5 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">All Blocks</option>
                    {filterOptions.blocks.map((block) => (
                      <option key={block} value={block}>
                        {block}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-600 block">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    placeholder="Filter by phone..."
                    value={filters.phoneNumber}
                    onChange={(e) =>
                      setFilters((prev) => ({
                        ...prev,
                        phoneNumber: e.target.value,
                      }))
                    }
                    className="w-full border border-gray-200 rounded-lg p-2.5 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Content Section */}
          <div className="p-6">
            {searchState.isSearching ? (
              <div className="flex justify-center items-center py-12 text-gray-500">
                <svg
                  className="animate-spin -ml-1 mr-3 h-8 w-8"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                <span className="text-lg">Searching...</span>
              </div>
            ) : filteredFarmers.length === 0 ? (
              <div className="text-center py-12">
                <div className="bg-yellow-50 inline-flex items-center px-6 py-4 rounded-lg text-yellow-700">
                  <AlertTriangle className="mr-3" size={24} />
                  No farmers found matching your criteria
                </div>
              </div>
            ) : (
              <>
                {/* Mobile View */}
                <div className="md:hidden space-y-4">
                  {paginatedFarmers.map((farmer) => (
                    <div
                      key={farmer.id}
                      className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-visible hover:shadow-md transition-shadow duration-200"
                    >
                      <div className="p-4">
                        <div className="flex justify-between items-start">
                          <div
                            onClick={() => setSelectedFarmer(farmer)}
                            className="cursor-pointer flex-grow"
                          >
                            <h3 className="font-bold text-lg text-gray-900">
                              {farmer.name}
                            </h3>
                            <div className="mt-2 space-y-1">
                              <p className="text-gray-600 flex items-center">
                                <Phone size={16} className="mr-2" />
                                {farmer.contactNumber}
                              </p>
                              <p className="text-gray-600 flex items-center">
                                <MapPin size={16} className="mr-2" />
                                {farmer.district}
                              </p>
                              <p className="text-gray-600 flex items-center">
                                <LayoutGrid size={16} className="mr-2" />
                                {farmer.block}
                              </p>
                            </div>
                          </div>
                          <div className="relative">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenMenuId(
                                  openMenuId === farmer.id ? null : farmer.id
                                );
                              }}
                              className="action-menu p-2 hover:bg-gray-100 rounded-full transition-colors"
                            >
                              <Menu size={20} />
                            </button>
                            {openMenuId === farmer.id && (
                              <div className="absolute right-0 mt-2 w-48 z-10">
                                <ActionMenu farmer={farmer} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Desktop View */}
                <div className="hidden md:block">
                  <div className="overflow-x-auto">
                    <table className="w-full">
                      <thead>
                        <tr className="bg-gray-50">
                          <th className="px-6 py-3 text-left text-sm font-semibold text-gray-600">
                            Name
                          </th>
                          <th className="px-6 py-3 text-left text-sm font-semibold text-gray-600">
                            Contact Number
                          </th>
                          <th className="px-6 py-3 text-left text-sm font-semibold text-gray-600">
                            District
                          </th>
                          <th className="px-6 py-3 text-left text-sm font-semibold text-gray-600">
                            Block
                          </th>
                          <th className="px-6 py-3 text-left text-sm font-semibold text-gray-600">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {paginatedFarmers.map((farmer) => (
                          <tr
                            key={farmer.id}
                            className="hover:bg-blue-50/50 transition-colors"
                          >
                            <td
                              className="px-6 py-4 cursor-pointer hover:text-blue-600 font-medium"
                              onClick={() => setSelectedFarmer(farmer)}
                            >
                              {farmer.name}
                            </td>
                            <td className="px-6 py-4 text-gray-600">
                              {farmer.contactNumber}
                            </td>
                            <td className="px-6 py-4 text-gray-600">
                              {farmer.district}
                            </td>
                            <td className="px-6 py-4 text-gray-600">
                              {farmer.block}
                            </td>
                            <td className="px-6 py-4">
                              <div className="flex items-center space-x-3">
                                <button
                                  onClick={() => handleEditFarmer(farmer)}
                                  className="text-blue-600 hover:text-blue-800 transition-colors"
                                  title="Edit"
                                >
                                  <Edit size={18} />
                                </button>
                                <button
                                  onClick={() => handlePayment(farmer)}
                                  className="text-purple-600 hover:text-purple-800 transition-colors"
                                  title="Add Payment"
                                >
                                  <CreditCard size={18} />
                                </button>
                                <button
                                  className="text-red-600 hover:text-red-800 transition-colors"
                                  title="Delete"
                                >
                                  <Trash2 size={18} />
                                </button>
                                <button
                                  onClick={() => exportFarmerToExcel(farmer)}
                                  className="text-green-600 hover:text-green-800 transition-colors"
                                  title="Export"
                                >
                                  <Download size={18} />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Pagination Controls */}
                <div className="mt-6 flex flex-col sm:flex-row justify-between items-center gap-4">
                  <div className="text-sm text-gray-600">
                    Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                    {Math.min(
                      currentPage * itemsPerPage,
                      filteredFarmers.length
                    )}{" "}
                    of {filteredFarmers.length} entries
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                      disabled={currentPage === 1}
                      className={`
                        px-4 py-2 rounded-lg font-medium transition-colors
                        ${
                          currentPage === 1
                            ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                            : "bg-white text-blue-600 hover:bg-blue-50 border border-blue-200"
                        }
                      `}
                    >
                      Previous
                    </button>
                    <div className="flex items-center space-x-1">
                      {Array.from({ length: totalPages }, (_, i) => i + 1)
                        .filter(
                          (page) =>
                            page === 1 ||
                            page === totalPages ||
                            Math.abs(page - currentPage) <= 1
                        )
                        .map((page, index, array) => (
                          <React.Fragment key={page}>
                            {index > 0 && array[index - 1] !== page - 1 && (
                              <span className="px-2 text-gray-400">...</span>
                            )}
                            <button
                              onClick={() => setCurrentPage(page)}
                              className={`
                                w-10 h-10 rounded-lg font-medium transition-colors
                                ${
                                  currentPage === page
                                    ? "bg-blue-600 text-white"
                                    : "bg-white text-gray-600 hover:bg-blue-50 border"
                                }`}
                            >
                              {page}
                            </button>
                          </React.Fragment>
                        ))}
                    </div>
                    <button
                      onClick={() =>
                        setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                      }
                      disabled={currentPage === totalPages}
                      className={`px-3 py-1 rounded-lg ${
                        currentPage === totalPages
                          ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                          : "bg-gray-200 hover:bg-gray-300 text-gray-700"
                      }`}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Farmer Details Modal */}
          {selectedFarmer && (
            <FarmerDetailsModal
              farmer={selectedFarmer}
              onClose={() => setSelectedFarmer(null)}
            />
          )}
        </div>

        {/* Toast Messages */}
      </div>
    </div>
  );
};

export default FarmerProfileManagement;
