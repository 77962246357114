import React from 'react';

const LandAccessibility = ({
  formData,
  handleInputChange,
  expandedSections,
  errors // Add this line to accept errors prop
}) => {
  return (
    <div className={`p-4 ${expandedSections.landAccessibility ? "block" : "hidden"}`}>
      <h3 className="font-semibold text-lg">Land Ownership and Lending Details</h3>

      {/* Land Ownership */}
      <div>
        <label className="block text-sm font-medium">Land Ownership</label>
        <label className="block">
          <input
            type="radio"
            name="landOwnership"
            value="self"
            checked={formData.landOwnership === "self"}
            onChange={handleInputChange}
            className="mr-2"
          />
          Self
        </label>
        <label className="block">
          <input
            type="radio"
            name="landOwnership"
            value="lease"
            checked={formData.landOwnership === "lease"}
            onChange={handleInputChange}
            className="mr-2"
          />
          Lease
        </label>
        {errors.landOwnership && (
          <p className="text-red-500 text-sm mt-1">{errors.landOwnership}</p>
        )}

        {formData.landOwnership === "lease" && (
          <div className="mt-4">
            <label className="block text-sm font-medium">Owner's Name</label>
            <input
              type="text"
              name="landOwnerName"
              value={formData.landOwnerName || ""}
              onChange={handleInputChange}
              className={`mt-1 block w-full border rounded-md p-2 ${errors.landOwnerName ? "border-red-500" : ""}`}
              placeholder="Enter owner's name"
            />
            {errors.landOwnerName && (
              <p className="text-red-500 text-sm mt-1">{errors.landOwnerName}</p>
            )}
          </div>
        )}
      </div>

      {/* Lending Land */}
      <div className="mt-4">
        <label className="block text-sm font-medium">Have you lent land to others?</label>
        <label className="block">
          <input
            type="radio"
            name="landLent"
            value="yes"
            checked={formData.landLent === "yes"}
            onChange={handleInputChange}
            className="mr-2"
          />
          Yes
        </label>
        <label className="block">
          <input
            type="radio"
            name="landLent"
            value="no"
            checked={formData.landLent === "no"}
            onChange={handleInputChange}
            className="mr-2"
          />
          No
        </label>
        {errors.landLent && (
          <p className="text-red-500 text-sm mt-1">{errors.landLent}</p>
        )}

        {formData.landLent === "yes" && (
          <div>
            <div className="mt-4">
              {/* Lender's Name */}
              <label className="block text-sm font-medium">Lender's Name</label>
              <input
                type="text"
                name="lenderName"
                value={formData.lenderName || ""}
                onChange={handleInputChange}
                className={`mt-1 block w-full border rounded-md p-2 ${errors.lenderName ? "border-red-500" : ""}`}
                placeholder="Enter lender's name"
              />
              {errors.lenderName && (
                <p className="text-red-500 text-sm mt-1">{errors.lenderName}</p>
              )}
            </div>

            {/* Land Type */}
            <div className="mt-4">
              <label className="block text-sm font-medium">Land Type</label>
              <select
                name="landType"
                value={formData.landType || ""}
                onChange={handleInputChange}
                className={`mt-1 block w-full border rounded-md p-2 ${errors.landType ? "border-red-500" : ""}`}
              >
                <option value="">Select Land Type</option>
                <option value="Khet">Khet</option>
                <option value="Bari">Bari</option>
              </select>
              {errors.landType && (
                <p className="text-red-500 text-sm mt-1">{errors.landType}</p>
              )}
            </div>

            {/* Area in Biga, Katha, Dhur */}
            <div className="mt-4">
              <label className="block text-sm font-medium">Area (in Biga, Katha, Dhur)</label>
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm">Biga</label>
                  <input
                    type="number"
                    name="areaBiga"
                    value={formData.areaBiga || ""}
                    onChange={handleInputChange}
                    className={`mt-1 block w-full border rounded-md p-2 ${errors.areaBiga ? "border-red-500" : ""}`}
                    placeholder="Enter area in Biga"
                  />
                  {errors.areaBiga && (
                    <p className="text-red-500 text-sm mt-1">{errors.areaBiga}</p>
                  )}
                </div>
                <div>
                  <label className="block text-sm">Katha</label>
                  <input
                    type="number"
                    name="areaKatha"
                    value={formData.areaKatha || ""}
                    onChange={handleInputChange}
                    className={`mt-1 block w-full border rounded-md p-2 ${errors.areaKatha ? "border-red-500" : ""}`}
                    placeholder="Enter area in Katha"
                  />
                  {errors.areaKatha && (
                    <p className="text-red-500 text-sm mt-1">{errors.areaKatha}</p>
                  )}
                </div>
                <div>
                  <label className="block text-sm">Dhur</label>
                  <input
                    type="number"
                    name="areaDhur"
                    value={formData.areaDhur || ""}
                    onChange={handleInputChange}
                    className={`mt-1 block w-full border rounded-md p-2 ${errors.areaDhur ? "border-red-500" : ""}`}
                    placeholder="Enter area in Dhur"
                  />
                  {errors.areaDhur && (
                    <p className="text-red-500 text-sm mt-1">{errors.areaDhur}</p>
                  )}
                </div>
              </div>
            </div>

            {/* Irrigation Facility */}
            <div className="mt-4">
              <label className="block text-sm font-medium">Irrigation Facility</label>
              <div>
                <label className="block">
                  <input
                    type="radio"
                    name="irrigationFacility"
                    value="yes"
                    checked={formData.irrigationFacility === "yes"}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  Yes
                </label>
                <label className="block">
                  <input
                    type="radio"
                    name="irrigationFacility"
                    value="no"
                    checked={formData.irrigationFacility === "no"}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  No
                </label>
              </div>
              {errors.irrigationFacility && (
                <p className="text-red-500 text-sm mt-1">{errors.irrigationFacility}</p>
              )}
            </div>

            {/* Irrigated Land Area */}
            {formData.irrigationFacility === "yes" && (
              <div className="mt-4">
                <label className="block text-sm font-medium">Irrigated Land Area</label>
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <label className="block text-sm">Biga</label>
                    <input
                      type="number"
                      name="irrigatedLandAreaBiga"
                      value={formData.irrigatedLandAreaBiga || ""}
                      onChange={handleInputChange}
                      className={`mt-1 block w-full border rounded-md p-2 ${errors.irrigatedLandAreaBiga ? "border-red-500" : ""}`}
                      placeholder="Enter area in Biga"
                    />
                    {errors.irrigatedLandAreaBiga && (
                      <p className="text-red-500 text-sm mt-1">{errors.irrigatedLandAreaBiga}</p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm">Katha</label>
                    <input
                      type="number"
                      name="irrigatedLandAreaKatha"
                      value={formData.irrigatedLandAreaKatha || ""}
                      onChange={handleInputChange}
                      className={`mt-1 block w-full border rounded-md p-2 ${errors.irrigatedLandAreaKatha ? "border-red-500" : ""}`}
                      placeholder="Enter area in Katha"
                    />
                    {errors.irrigatedLandAreaKatha && (
                      <p className="text-red-500 text-sm mt-1">{errors.irrigatedLandAreaKatha}</p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm">Dhur</label>
                    <input
                      type="number"
                      name="irrigatedLandAreaDhur"
                      value={formData.irrigatedLandAreaDhur || ""}
                      onChange={handleInputChange}
                      className={`mt-1 block w-full border rounded-md p-2 ${errors.irrigatedLandAreaDhur ? "border-red-500" : ""}`}
                      placeholder="Enter area in Dhur"
                    />
                    {errors.irrigatedLandAreaDhur && (
                      <p className="text-red-500 text-sm mt-1">{errors.irrigatedLandAreaDhur}</p>
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-sm font-medium">Irrigation Source</label>
                  <input
                    type="text"
                    name="irrigationSource"
                    value={formData.irrigationSource || ""}
                    onChange={handleInputChange}
                    className={`mt-1 block w-full border rounded-md p-2 ${errors.irrigationSource ? "border-red-500" : ""}`}
                    placeholder="Enter irrigation source"
                  />
                  {errors.irrigationSource && (
                    <p className="text-red-500 text-sm mt-1">{errors.irrigationSource}</p>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LandAccessibility;