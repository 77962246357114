import React from "react";

const FamilyDetails = ({
  formData,
  setFormData,
  handleInputChange,
  handleCheckboxChange,
  handleNestedCheckboxChange,
  handleNestedInputChange,
  expandedSections,
  errors,
}) => {
  const genders = ["male", "female", "other"];
  const agricultureAreas = [
    "उत्पादन क्षेत्र",
    "प्रशोधन क्षेत्र",
    "बजारिकरण",
    "कृषि उद्यम",
    "कृषि श्रमिक",
  ];

  return (
    <div
      className={`p-4 ${expandedSections.familyDetails ? "block" : "hidden"}`}
    >
      <div className="grid md:grid-cols-2 gap-4">
        {/* Total Family Members */}
        <div>
          <label className="block text-sm font-medium">
            Total Family Members
          </label>
          <input
            type="number"
            min="0"
            name="totalFamilyMembers"
            value={formData.totalFamilyMembers}
            onChange={(e) => {
              const newTotal = parseInt(e.target.value) || 0;

              setFormData((prev) => ({
                ...prev,
                totalFamilyMembers: newTotal,
                // Automatically adjust familyMembersInAgriculture if it exceeds new total
                familyMembersInAgriculture: Math.min(
                  prev.familyMembersInAgriculture || 0,
                  newTotal
                ),
              }));
            }}
            className={`mt-1 block w-full border rounded-md p-2 ${
              errors.totalFamilyMembers ? "border-red-500" : ""
            }`}
          />
          {errors.totalFamilyMembers && (
            <p className="text-red-500 text-sm mt-1">
              {errors.totalFamilyMembers}
            </p>
          )}
        </div>

        {/* Family Members in Agriculture */}
        <div>
          <label className="block text-sm font-medium">
            Family Members in Agriculture
          </label>
          <input
            type="number"
            min="0"
            max={formData.totalFamilyMembers || 0}
            name="familyMembersInAgriculture"
            value={formData.familyMembersInAgriculture}
            onChange={(e) => {
              const value = parseInt(e.target.value) || 0;
              const maxAllowed = formData.totalFamilyMembers || 0;

              if (value >= 0 && value <= maxAllowed) {
                setFormData((prev) => ({
                  ...prev,
                  familyMembersInAgriculture: value,
                }));
              }
            }}
            onBlur={(e) => {
              const value = parseInt(e.target.value) || 0;
              const maxAllowed = formData.totalFamilyMembers || 0;

              if (value > maxAllowed) {
                setFormData((prev) => ({
                  ...prev,
                  familyMembersInAgriculture: maxAllowed,
                }));
              }
            }}
            className={`mt-1 block w-full border rounded-md p-2 ${
              errors.familyMembersInAgriculture ? "border-red-500" : ""
            }`}
          />
          {errors.familyMembersInAgriculture && (
            <p className="text-red-500 text-sm mt-1">
              {errors.familyMembersInAgriculture}
            </p>
          )}
        </div>

        {/* Total Genders */}
        <div className="col-span-2">
          <label className="block text-sm font-medium">Total Genders</label>
          <div className="flex flex-wrap gap-4 mt-1">
            {genders.map((gender) => (
              <div key={gender} className="flex items-center">
                <input
                  type="number"
                  min="0"
                  name={`totalGendersInFamily.${gender}`}
                  value={formData.totalGendersInFamily[gender] || 0}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value) || 0;
                    const otherGendersSum = Object.entries(
                      formData.totalGendersInFamily
                    )
                      .filter(([key]) => key !== gender)
                      .reduce((sum, [_, value]) => sum + (value || 0), 0);

                    // Check if new total would exceed totalFamilyMembers
                    if (
                      newValue >= 0 &&
                      newValue + otherGendersSum <= formData.totalFamilyMembers
                    ) {
                      setFormData((prev) => ({
                        ...prev,
                        totalGendersInFamily: {
                          ...prev.totalGendersInFamily,
                          [gender]: newValue,
                        },
                      }));
                    }
                  }}
                  onBlur={(e) => {
                    const currentValue = parseInt(e.target.value) || 0;
                    const otherGendersSum = Object.entries(
                      formData.totalGendersInFamily
                    )
                      .filter(([key]) => key !== gender)
                      .reduce((sum, [_, value]) => sum + (value || 0), 0);

                    // If total exceeds, adjust current gender value
                    if (
                      currentValue + otherGendersSum >
                      formData.totalFamilyMembers
                    ) {
                      const maxAllowed = Math.max(
                        0,
                        formData.totalFamilyMembers - otherGendersSum
                      );
                      setFormData((prev) => ({
                        ...prev,
                        totalGendersInFamily: {
                          ...prev.totalGendersInFamily,
                          [gender]: maxAllowed,
                        },
                      }));
                    }
                  }}
                  className="w-16 border rounded-md p-2 mr-2"
                />
                <label className="text-sm">
                  {gender.charAt(0).toUpperCase() + gender.slice(1)}
                  {gender === "other"}
                </label>
              </div>
            ))}
          </div>
          {/* Display total count */}
          <div className="mt-2 text-sm text-gray-600">
            Total:{" "}
            {Object.values(formData.totalGendersInFamily).reduce(
              (sum, value) => sum + (value || 0),
              0
            )}
            /{formData.totalFamilyMembers} members
          </div>
          {errors.totalGendersInFamily && (
            <p className="text-red-500 text-sm mt-1">
              {errors.totalGendersInFamily}
            </p>
          )}
        </div>

        {/* Additional Details (Conditionally Rendered) */}
        {formData.familyMembersInAgriculture > 1 && ( // Change condition to > 1
          <div className="mt-4 col-span-2">
            <h3 className="text-lg font-semibold mb-2">
              Details of Family Members in Agriculture
            </h3>
            <div className="grid gap-4">
              {Array.from({
                length: formData.familyMembersInAgriculture - 1,
              }).map((_, index) => (
                <div
                  key={index}
                  className="grid md:grid-cols-3 gap-6 border p-4 rounded-lg shadow-sm mb-4"
                >
                  <div className="col-span-3 mb-2">
                    <h4 className="text-lg font-semibold">
                      Family Member {index + 1}
                    </h4>
                  </div>

                  {/* Full Name */}
                  <div>
                    <label className="block text-sm font-medium">
                      Full Name
                    </label>
                    <input
                      type="text"
                      name={`familyMemberDetails[${index}].fullName`}
                      value={
                        formData.familyMemberDetails?.[index]?.fullName || ""
                      }
                      onChange={(e) =>
                        handleNestedInputChange(
                          "familyMemberDetails",
                          index,
                          "fullName",
                          e.target.value
                        )
                      }
                      className={`mt-1 block w-full border rounded-md p-2 ${
                        errors[`familyMemberDetails[${index}].fullName`]
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    {errors[`familyMemberDetails[${index}].fullName`] && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors[`familyMemberDetails[${index}].fullName`]}
                      </p>
                    )}
                  </div>

                  {/* Relation with Farmer */}
                  <div>
                    <label className="block text-sm font-medium">
                      Relation with Farmer
                    </label>
                    <input
                      type="text"
                      name={`familyMemberDetails[${index}].relation`}
                      value={
                        formData.familyMemberDetails?.[index]?.relation || ""
                      }
                      onChange={(e) =>
                        handleNestedInputChange(
                          "familyMemberDetails",
                          index,
                          "relation",
                          e.target.value
                        )
                      }
                      className={`mt-1 block w-full border rounded-md p-2 ${
                        errors[`familyMemberDetails[${index}].relation`]
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    {errors[`familyMemberDetails[${index}].relation`] && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors[`familyMemberDetails[${index}].relation`]}
                      </p>
                    )}
                  </div>

                  {/* Age */}
                  <div>
                    <label className="block text-sm font-medium">Age</label>
                    <input
                      type="number"
                      min="0"
                      name={`familyMemberDetails[${index}].age`}
                      value={formData.familyMemberDetails?.[index]?.age || ""}
                      onChange={(e) =>
                        handleNestedInputChange(
                          "familyMemberDetails",
                          index,
                          "age",
                          e.target.value
                        )
                      }
                      className={`mt-1 block w-full border rounded-md p-2 ${
                        errors[`familyMemberDetails[${index}].age`]
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    {errors[`familyMemberDetails[${index}].age`] && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors[`familyMemberDetails[${index}].age`]}
                      </p>
                    )}
                  </div>

                  {/* Gender */}
                  <div>
                    <label className="block text-sm font-medium">Gender</label>
                    <div className="flex gap-4">
                      {genders.map((gender) => (
                        <div key={gender} className="flex items-center">
                          <input
                            type="radio"
                            id={`${gender}-${index}`}
                            name={`familyMemberDetails[${index}].gender`}
                            value={gender}
                            checked={
                              formData.familyMemberDetails?.[index]?.gender ===
                              gender
                            }
                            onChange={(e) =>
                              handleNestedInputChange(
                                "familyMemberDetails",
                                index,
                                "gender",
                                e.target.value
                              )
                            }
                            className="mr-2"
                          />
                          <label
                            htmlFor={`${gender}-${index}`}
                            className="text-sm"
                          >
                            {gender.charAt(0).toUpperCase() + gender.slice(1)}
                          </label>
                        </div>
                      ))}
                    </div>
                    {errors[`familyMemberDetails[${index}].gender`] && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors[`familyMemberDetails[${index}].gender`]}
                      </p>
                    )}
                  </div>

                  {/* Area of Agriculture */}
                  <div className="col-span-3">
                    <label className="block text-sm font-medium">
                      Area of Agriculture
                    </label>
                    <div className="flex flex-wrap gap-2">
                      {agricultureAreas.map((area) => (
                        <div key={area} className="flex items-center">
                          <input
                            type="checkbox"
                            name={`familyMemberDetails[${index}].areaOfAgriculture`}
                            value={area}
                            checked={
                              formData.familyMemberDetails?.[
                                index
                              ]?.areaOfAgriculture?.includes(area)
                            }
                          
                            onChange={(e) =>
                              handleNestedInputChange(
                                "familyMemberDetails",
                                index,
                                "areaOfAgriculture",
                                e.target.value
                              )
                            }

                            className="mr-2"
                          />
                          
                          <label className="text-sm">{area}</label>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Citizenship No */}
                  <div>
                    <label className="block text-sm font-medium">
                      Citizenship No
                    </label>
                    <input
                      type="text"
                      name={`familyMemberDetails[${index}].citizenshipNo`}
                      value={
                        formData.familyMemberDetails?.[index]?.citizenshipNo ||
                        ""
                      }
                      onChange={(e) =>
                        handleNestedInputChange(
                          "familyMemberDetails",
                          index,
                          "citizenshipNo",
                          e.target.value
                        )
                      }
                      className={`mt-1 block w-full border rounded-md p-2 ${
                        errors[`familyMemberDetails[${index}].citizenshipNo`]
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    {errors[`familyMemberDetails[${index}].citizenshipNo`] && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors[`familyMemberDetails[${index}].citizenshipNo`]}
                      </p>
                    )}
                  </div>

                  {/* Phone Number */}
                  <div>
                    <label className="block text-sm font-medium">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      name={`familyMemberDetails[${index}].phoneNumber`}
                      value={
                        formData.familyMemberDetails?.[index]?.phoneNumber || ""
                      }
                      onChange={(e) =>
                        handleNestedInputChange(
                          "familyMemberDetails",
                          index,
                          "phoneNumber",
                          e.target.value
                        )
                      }
                      className={`mt-1 block w-full border rounded-md p-2 ${
                        errors[`familyMemberDetails[${index}].phoneNumber`]
                          ? "border-red-500"
                          : ""
                      }`}
                    />
                    {errors[`familyMemberDetails[${index}].phoneNumber`] && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors[`familyMemberDetails[${index}].phoneNumber`]}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FamilyDetails;