import React from 'react';
import AddressField from './AddressField';

const AddressForm = ({
  addressType,
  formData,
  handleTwoLevelInputChange,
  title,
  showCopyButton,
  onCopy,
  errors, // Added errors prop
}) => {
  const addressFields = [
    { name: 'province', label: 'Province' },
    { name: 'block', label: 'Block' },
    { name: 'district', label: 'District' },
    { name: 'municipality', label: 'Municipality' },
    { name: 'ward', label: 'Ward' },
    { name: 'tole', label: 'Tole' },
  ];

  return (
    <div className="mb-6">
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      {showCopyButton && (
        <button
          type="button"
          onClick={onCopy}
          className="mb-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
        >
          Same as Permanent Address
        </button>
      )}
      <div className="grid md:grid-cols-3 gap-4">
        {addressFields.map((field) => (
          <AddressField
            key={field.name}
            label={field.label}
            value={formData[addressType][field.name]}
            onChange={(e) =>
              handleTwoLevelInputChange(addressType, field.name, e.target.value)
            }
            error={errors?.[`${addressType}.${field.name}`]} // Display error for this field
          />
        ))}
      </div>
    </div>
  );
};

export default AddressForm;
