import React from 'react';

const IncomeAndResources = ({ formData, handleInputChange, expandedSections, errors }) => {
  const timeSpentOptions = [
    { value: "3", label: "3 Months" },
    { value: "6", label: "6 Months" },
    { value: "9", label: "9 Months" },
    { value: "12", label: "12 Months" },
  ];

  return (
    <div className={`p-4 ${expandedSections.income ? "block" : "hidden"}`}>
      {/* Annual Income from Agriculture */}
      <div className="mb-4">
        <label className="block text-sm font-medium">
          Annual Income from Agriculture (NPR)
        </label>
        <input
          type="number"
          name="annualIncomeFromAgriculture"
          value={formData.annualIncomeFromAgriculture}
          onChange={handleInputChange}
          className={`mt-1 block w-full border rounded-md p-2 ${
            errors?.annualIncomeFromAgriculture ? "border-red-500" : ""
          }`}
          placeholder="Enter annual income in NPR"
        />
        {errors?.annualIncomeFromAgriculture && (
          <p className="text-red-500 text-sm mt-1">{errors.annualIncomeFromAgriculture}</p>
        )}
      </div>

      {/* Annual Income from Other Sector */}
      <div className="mb-4">
        <label className="block text-sm font-medium">
          Annual Income from Other Sector (NPR)
        </label>
        <input
          type="number"
          name="annualIncomeFromOther"
          value={formData.annualIncomeFromOther}
          onChange={handleInputChange}
          className={`mt-1 block w-full border rounded-md p-2 ${
            errors?.annualIncomeFromOther ? "border-red-500" : ""
          }`}
          placeholder="Enter annual income in NPR"
        />
        {errors?.annualIncomeFromOther && (
          <p className="text-red-500 text-sm mt-1">{errors.annualIncomeFromOther}</p>
        )}
      </div>

      {/* Time Spent in Agriculture */}
      <div className="mb-4">
        <label className="block text-sm font-medium">
          Time Spent in Agriculture/Year
        </label>
        <div className="grid grid-cols-2 gap-4 mt-1 w-full">
          {timeSpentOptions.map((option) => (
            <div key={option.value} className="flex items-center">
              <input
                type="radio"
                id={`${option.value}months`}
                name="timeSpentInAgriculture"
                value={option.value}
                checked={formData.timeSpentInAgriculture === option.value}
                onChange={handleInputChange}
                className="mr-2"
              />
              <label htmlFor={`${option.value}months`}>{option.label}</label>
            </div>
          ))}
        </div>
        {errors?.timeSpentInAgriculture && (
          <p className="text-red-500 text-sm mt-1">{errors.timeSpentInAgriculture}</p>
        )}
      </div>
    </div>
  );
};

export default IncomeAndResources;
