import React from 'react';

const LandHoldings = ({
  formData,
  handleNestedInputChange,
  handleCheckboxChange,
  expandedSections,
  errors,
}) => {
  const landAreas = ["bigha", "katha", "dhur"];
  const landTypes = ["khet", "bari"];

  return (
    <div className={`p-4 ${expandedSections.landHoldings ? "block" : "hidden"}`}>
      {/* Land Area Inputs */}
      <h3 className="text-lg font-medium mb-4">Land Area</h3>
      <div className="grid md:grid-cols-3 gap-4">
        {landAreas.map((areaType) => (
          <div key={areaType}>
            <label className="block text-sm font-medium">
              Land Size ({areaType.charAt(0).toUpperCase() + areaType.slice(1)})
            </label>
            <input
              type="number"
              name={`landArea.${areaType}`}
              value={formData.landArea?.[areaType]?.number || ""}
              onChange={(e) =>
                handleNestedInputChange(
                  "landArea",
                  areaType,
                  "number",
                  e.target.value
                )
              }
              className={`mt-1 block w-full border rounded-md p-2 ${
                errors?.[`landArea.${areaType}`] ? "border-red-500" : ""
              }`}
              min="0"
            />
            {errors?.[`landArea.${areaType}`] && (
              <p className="text-red-500 text-sm mt-1">
                {errors[`landArea.${areaType}`]}
              </p>
            )}
          </div>
        ))}
      </div>

      {/* Land Type Inputs */}
      <fieldset className="mb-4">
        <legend className="text-lg font-medium">Land Type</legend>
        <div className="flex items-center space-x-4">
          {landTypes.map((landType) => (
            <label key={landType}>
              <input
                type="checkbox"
                value={landType}
                checked={formData.landType?.includes(landType)}
                onChange={(e) => handleCheckboxChange(e, "landType")}
                className="mr-2"
                aria-label={landType.charAt(0).toUpperCase() + landType.slice(1)}
              />
              {landType.charAt(0).toUpperCase() + landType.slice(1)}
            </label>
          ))}
        </div>
        {errors?.landType && (
          <p className="text-red-500 text-sm mt-1">{errors.landType}</p>
        )}
      </fieldset>
    </div>
  );
};

export default LandHoldings;
